import axios from 'axios';
import { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FaRegEdit } from 'react-icons/fa';
import { ImUserMinus } from 'react-icons/im';
import styles from './Cela.module.css';
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit';
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { Input } from 'reactstrap';
import { Form } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Label } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../firebase/config';
import userDef from '../assets/img/User-Default3.jpg'
import "firebase/storage";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Loading from './Loading'
import { MdOutlineCleaningServices } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { useData } from '../DataContext';


const Cela = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editPresoId, setEditPresoId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imgURL, setImgURL] = useState("")
  const [startDate] = useState(new Date());
  const [nome, setNome] = useState("")
  const [cela, setCela] = useState("")
  const [origem, setOrigem] = useState("")
  const [infopen, setInfopen] = useState("")
  const [artigo, setArtigo] = useState("")
  const [selectDate, setSelectDate] = useState('');
  const [modalDeleteAberto, setModalDeleteAberto] = useState(false);
  const [presoNomeDELETE, setPresoNomeDELETE] = useState('')
  const [presoIdDELETE, setPresoIdDELETE] = useState('')
  const [presoEntradaDELETE, setPresoEntradaDELETE] = useState('')
  const [presoUrlDELETE, setPresoUrlDELETE] = useState('')
  const [presoCelaDELETE, setPresoCelaDELETE] = useState('')
  const [presoOrigemDELETE, setPresoOrigemDELETE] = useState('')
  const [presoInfopenDELETE, setPresoInfopenDELETE] = useState('')
  const [selecionado, setSelecionado] = useState('');
  const [motivoSelecionado, setMotivoSelecionado] = useState(0);
  const [disable, setDisable] = useState(true)
  const hoje = new Date();
  const dia = hoje.getDate();
  const mes = hoje.getMonth() + 1; // Janeiro é 0
  const ano = hoje.getFullYear();
  const hora = hoje.getHours();
  const minuto = hoje.getMinutes();
  const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}`;
  const [usuarioLogado, setUsuarioLogado] = useState('');
  const [selecao, setSelecao] = useState('');
  const [visita, setVisita] = useState('');
  const [galerias] = useState([])
  const navigate = useNavigate();
  const [botaoClicado, setBotaoClicado] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false)
  const [dataFormatada2] = useState(`${String(dia).padStart(2, '0')}/${String(mes).padStart(2, '0')}/${ano} ${String(hora).padStart(2, '0')}:${String(minuto).padStart(2, '0')}`)
  const [copied, setCopied] = useState(false);
  const [sitIsolada, setSitIsolada] = useState(false)
  const [tipoCela, setTipoCela] = useState(''); // Estado para armazenar o valor do input
  const [tipoCelaAtual, setTipoCelaAtual] = useState(null); // Estado para armazenar o valor do input
  const datacela = useData([]);
  const dataisolamento = useData([]);
  const { atualizarCela } = useData();
  const [dataisolamento2, setDataIsolamento2] = useState(JSON.parse(dataisolamento.dataisolamento))
const [nomeSetado, setNomeSetado] = useState('')
const [un, setUn] = useState('')

useEffect(() => {
  
  setNomeSetado(props.nomeBuscado)

  setTimeout(() => {
    setNomeSetado('')
  }, 3000); 
  
}, [props.nomeBuscado])


  console.log('data isolamentoooooooooooo XXXXXX ' + dataisolamento2)

  console.log('data cela importado--->', datacela);
  console.log('novoooooo isolamento cela importado--->', JSON.parse(dataisolamento.dataisolamento))



  useEffect(() => {
    const celaProcurada = props.cela;

    // Encontra a descrição da cela
    for (const cela of datacela.datacela) {
      if (cela.cela === celaProcurada) {
        console.log("Descrição da cela:", cela.descricao);
        setTipoCelaAtual(cela.descricao)
        break;
      }
    }

  }, [])



  useEffect(() => {
    const resultado = dataisolamento2.find(item => item.cela.includes(props.cela));
    try {
      if (resultado != null) {

        console.log('xxxxxxxxxxxxxxxxx' + resultado);
        setSitIsolada(resultado !== null);
      } else {
        setSitIsolada(false);
      }
    } catch (error) {
      console.error(error); // Registre o erro no console
      setSitIsolada(false); // Defina SitIsolada como false em caso de erro
    }
  }, [props.cela]);


  const handleInputChange = (event) => {
    setTipoCela(event.target.value.toUpperCase());
  };





  const addDescricao = (celaDesc) => {
    const dados = {
      cela: celaDesc,
      descricao: tipoCela
    };

    axios.post('https://alertadiarioes.com/apicdpvv/insert_cela_descricao.php', dados)
      .then((response) => {
        setTipoCelaAtual(tipoCela)
        setTipoCela('')
        atualizarCela()
      })
      .catch((error) => {
        console.error('Erro ao enviar dados para a API:', error);
      });

  }

  const removeDescricao = (celaDesc) => {

    const dados = {

      cela: celaDesc,


    };

    axios.post('https://alertadiarioes.com/apicdpvv/delete_cela_descricao.php', dados)
      .then((response) => {
        setTipoCelaAtual(null)
        setTipoCela('')
        atualizarCela()

      })
      .catch((error) => {
        console.error('Erro ao enviar dados para a API:', error);
      });

  }


  const addIsolamento = (celaIsolada) => {

    const dados = {

      cela: celaIsolada,

    };

    axios.post('https://alertadiarioes.com/apicdpvv/insert_cela_isolamento.php', dados)
      .then((response) => {
        setSitIsolada(true)
        atualizarCela()
      })
      .catch((error) => {
        console.error('Erro ao enviar dados para a API:', error);
      });

  }



  const removeIsolamento = (celaIsolada) => {

    const dados = {

      cela: celaIsolada,

    };

    axios.post('https://alertadiarioes.com/apicdpvv/delete_cela_isolamento.php', dados)
      .then((response) => {
        setSitIsolada(false)
        atualizarCela()
      })
      .catch((error) => {
        console.error('Erro ao enviar dados para a API:', error);
      });

  }







  const copyToClipboard = (presonome) => {
    // Cria um input invisível para copiar o texto para a área de transferência
    const input = document.createElement('input');
    input.value = presonome;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    // Atualiza o estado para mostrar que o texto foi copiado
    setCopied(true);

    // Reseta o estado após 2 segundos para remover a mensagem de "copiado"
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };



  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Usuário logado: ', user.email);
        setUsuarioLogado((user.email).toUpperCase());
      } else {
        console.log('Nenhum usuário logado.');
        setUsuarioLogado('');
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const enviarDadosParaAPI = (editPresoId) => {
    // Aqui você pode fazer a solicitação HTTP para enviar os dados para a API PHP
    axios.get(`https://alertadiarioes.com/apicdpvv/get_id_galerias_cdpg.php?id=${editPresoId}`)
      .then(response => {
        console.log('id enviado para api' + editPresoId)
        console.log('response---->' + JSON.stringify(response.data[0]))
        const primeiroObjeto = response.data[0];
        const { id, artigo, nome, selectDate, infopen, visita, imgURL, origem, cela, selecao, un } = primeiroObjeto;
        setEditPresoId(editPresoId);
        setArtigo(artigo);
        setNome(nome);
        setSelectDate(selectDate);
        setInfopen(infopen);
        setVisita(visita);
        setImgURL(imgURL);
        setOrigem(origem);
        setCela(cela);
        setSelecao(selecao);
        setUn(un)

      
      })
      .catch(error => {
        // Manipule os erros, se houver
        console.error('Erro ao enviar dados para a API:', error);
      });
  };





  const handleChange = (event) => {
    setSelecionado(event.target.value);
    setCela(selecionado)

  };
  const handleChangeMotivo = (event) => {
    setMotivoSelecionado(event.target.value);


  };


  useEffect(() => {

    setCela(selecionado)
    if (motivoSelecionado == 0) {
      setDisable(true)
    } else { setDisable(false) }
    if (selecionado === 'HOSPITAL' || selecionado === 'PERNOITE') {

    } else {


    }

  }, [selecionado, motivoSelecionado])


  const handleDelete = async (id, presonomedelete, presoentradadelete, presoceladelete, presourldelete, presoinfopendelete, presoorigemdelete, usuarioLogado) => {
    // Excluir documento do Firestore
    setLoading(true)
    toggleModalDelete();

    if (presoUrlDELETE) {
      setLoading(true)
      const storageRef = ref(storage, presoUrlDELETE);
      try {
        setLoading(true)
        await deleteObject(storageRef);
      } catch (error) {
        console.error('Erro ao excluir imagem:', error);
      }
    }

    console.log(motivoSelecionado)

    // Excluir imagens associadas ao documento
    var tipoSaida = motivoSelecionado;

    switch (tipoSaida) {
      case '1':
        tipoSaida = "ALVARÁ";
        break;
      case '2':
        tipoSaida = "TRANSFERÊNCIA";
        break;
      default:
        tipoSaida = "OUTRO";
    }


    console.log(tipoSaida)
    const dados = {
      nome: presonomedelete,
      cela: presoceladelete,
      dataEntrada: presoentradadelete,
      infopen: presoinfopendelete,
      origem: presoorigemdelete,
      dataRegistro: dataFormatada2,
      usuario: usuarioLogado,
      tipo: 'SAÍDA',
      motivoSaida: tipoSaida
    };

    try {
      // Enviar dados para a segunda API
      const response = await axios.post('https://alertadiarioes.com/apicdpvv/post_movimento.php', dados);
      console.log('Resposta da API de exclusão:', response.data);

      // Aqui você pode lidar com a resposta da API, se necessário
    } catch (error) {
      console.error('Erro ao enviar dados para a API de exclusão:', error);
    }

    try {
      const response2 = await axios.post('https://alertadiarioes.com/apicdpvv/delete_galerias_id.php', { uid_usuario: id });
      navigate('/');
      window.location.reload();
      // Aqui você pode lidar com a resposta da API, se necessário
    } catch (error) {
      console.error('Erro ao Deletar Registro', error);
    }
  };


  const toggleModalDelete = (presoId, presoNome, presoEntrada, presoUrl, presoCela, presoOrigem, presoInfopen) => {

    setModalDeleteAberto(!modalDeleteAberto);
    setPresoNomeDELETE(presoNome)
    setPresoEntradaDELETE(presoEntrada)
    setPresoUrlDELETE(presoUrl)
    setPresoIdDELETE(presoId)
    setPresoCelaDELETE(presoCela)
    setPresoOrigemDELETE(presoOrigem)
    setPresoInfopenDELETE(presoInfopen)
    setMotivoSelecionado(0)

  };


  const toggleModal = (presoId) => {
    setEditPresoId(presoId)
    console.log('---->preoi modal' + editPresoId)
    enviarDadosParaAPI(presoId);
    setUploadedImage('')
    setImgURL('')
    setModalIsOpen(!modalIsOpen);
    setEditPresoId('')

  };
  const handleDrop = (acceptedFiles) => {
    // Acessar o arquivo de imagem carregado
    const file = acceptedFiles[0];

    // Criar uma URL temporária para exibição da imagem
    const imageUrl = URL.createObjectURL(file);

    // Atualizar o estado com a imagem carregada
    setUploadedImage(imageUrl);

    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result1 = ' ';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log(result1)

    const storageRef = ref(storage, `images/${result1 + file.name}`)


    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      "state_changed",
      snapshot => {



      },

      error => {

        alert(error)
      },

      () => {

        getDownloadURL(uploadTask.snapshot.ref).then(url => {

          setImgURL(url)
          console.log(url)


        })
      }


    )
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: handleDrop
  });


  const handleSubmit = async (e) => {
    
    e.preventDefault();
    setBotaoClicado(true);
    

    // Definir valores padrão se visit e selecao forem undefined
    if (visita === undefined) {
      setVisita('');
    }
    if (selecao === undefined) {
      setSelecao('');
    }

    // Construir objeto de dados para enviar para a API
    const data = {
      editPresoId,
      nome,
      cela,
      origem,
      infopen,
      selectDate,
      artigo,
      imgURL,
      selecao,
      visita,
      un
    };

    // Função interna para enviar dados para a API
    async function enviarDadosParaAPI(data) {
      const url = 'https://alertadiarioes.com/apicdpvv/update_id_galerias_cdpg.php';

      try {
        // Envia uma requisição POST para a API com os dados
        const response = await axios.post(url, data);

        // Exibe a resposta da API no console
        console.log('Resposta da API:', response.data);
      } catch (error) {
        // Exibe os erros no console, se houver algum
        console.error('Erro ao enviar dados para a API:', error);
      }
    }
    console.log('data funcao ----->' + data)
    // Chama a função para enviar dados para a API
    await enviarDadosParaAPI(data);
    const dados = {
      nome: nome,
      cela: cela,
      dataEntrada: selectDate,
      infopen: infopen,
      origem: origem,
      dataRegistro: dataFormatada2,
      usuario: usuarioLogado,
      un: un,
      tipo: 'EDIÇÃO',
    };

    // Construir objeto de dados para enviar para a segunda API
    async function enviarMovimento() {

      try {
        // Enviar dados para a segunda API
        const response = await axios.post('https://alertadiarioes.com/apicdpvv/post_movimento.php', dados);
        console.log('dados:---->' + dados);
        console.log('Resposta da API:', response.data);
        window.location.reload();
        // Aqui você pode lidar com a resposta da API, se necessário
      } catch (error) {
        console.error('Erro ao enviar dados para a API:', error);
      }
    }
    // Limpar os estados e redirecionar para a página inicial
    setUploadedImage('');
    setImgURL('');
    toggleModal();
    enviarMovimento()
    setBotaoClicado(false);



  }

  //listagem dos campos do select para cela



  const list = [


    // QUANDRANTE A101 A A112

    { id: 'A101', name: 'A101' },
    { id: 'A102', name: 'A102' },
    { id: 'A103', name: 'A103' },
    { id: 'A104', name: 'A104' },
    { id: 'A105', name: 'A105' },
    { id: 'A106', name: 'A106' },
    { id: 'A107', name: 'A107' },
    { id: 'A108', name: 'A108' },
    { id: 'A109', name: 'A109' },
    { id: 'A110', name: 'A110' },
    { id: 'A111', name: 'A111' },
    { id: 'A112', name: 'A112' },

    // QUANDRANTE A113 A A122

    { id: 'A113', name: 'A113' },
    { id: 'A114', name: 'A114' },
    { id: 'A115', name: 'A115' },
    { id: 'A116', name: 'A116' },
    { id: 'A117', name: 'A117' },
    { id: 'A118', name: 'A118' },
  

    // QUANDRANTE A201 A A212

    { id: 'A201', name: 'A201' },
    { id: 'A202', name: 'A202' },
    { id: 'A203', name: 'A203' },
    { id: 'A204', name: 'A204' },
    { id: 'A205', name: 'A205' },
    { id: 'A206', name: 'A206' },
    { id: 'A207', name: 'A207' },
    { id: 'A208', name: 'A208' },
    { id: 'A209', name: 'A209' },
    { id: 'A210', name: 'A210' },
    { id: 'A211', name: 'A211' },
    { id: 'A212', name: 'A212' },

    // QUANDRANTE A213 A A222

    { id: 'A213', name: 'A213' },
    { id: 'A214', name: 'A214' },
    { id: 'A215', name: 'A215' },
    { id: 'A216', name: 'A216' },
    { id: 'A217', name: 'A217' },
    { id: 'A218', name: 'A218' },
 



    // GALERIA CRAVO


    // QUANDRANTE A101 A A112

    { id: 'B101', name: 'B101' },
    { id: 'B102', name: 'B102' },
    { id: 'B103', name: 'B103' },
    { id: 'B104', name: 'B104' },
    { id: 'B105', name: 'B105' },
    { id: 'B106', name: 'B106' },
    { id: 'B107', name: 'B107' },
    { id: 'B108', name: 'B108' },
    { id: 'B109', name: 'B109' },
    { id: 'B110', name: 'B110' },
    { id: 'B111', name: 'B111' },
    { id: 'B112', name: 'B112' },

    // QUBNDRBNTE B113 B B122

    { id: 'B113', name: 'B113' },
    { id: 'B114', name: 'B114' },
    { id: 'B115', name: 'B115' },
    { id: 'B116', name: 'B116' },
    { id: 'B117', name: 'B117' },
    { id: 'B118', name: 'B118' },
    { id: 'B119', name: 'B119' },
    { id: 'B120', name: 'B120' },
    { id: 'B121', name: 'B121' },
    { id: 'B122', name: 'B122' },

    // QUBNDRBNTE B201 B B212
    { id: 'B201', name: 'B201' },
    { id: 'B202', name: 'B202' },
    { id: 'B203', name: 'B203' },
    { id: 'B204', name: 'B204' },
    { id: 'B205', name: 'B205' },
    { id: 'B206', name: 'B206' },
    { id: 'B207', name: 'B207' },
    { id: 'B208', name: 'B208' },
    { id: 'B209', name: 'B209' },
    { id: 'B210', name: 'B210' },
    { id: 'B211', name: 'B211' },
    { id: 'B212', name: 'B212' },
    // QUBNDRBNTE B213 B B222
    { id: 'B213', name: 'B213' },
    { id: 'B214', name: 'B214' },
    { id: 'B215', name: 'B215' },
    { id: 'B216', name: 'B216' },
    { id: 'B217', name: 'B217' },
    { id: 'B218', name: 'B218' },
    { id: 'B219', name: 'B219' },
    { id: 'B220', name: 'B220' },
    { id: 'B221', name: 'B221' },
    { id: 'B222', name: 'B222' },

    // GALERIA CHARLIE 
    // QUANDRANTE A101 A A112
    { id: 'C101', name: 'C101' },
    { id: 'C102', name: 'C102' },
    { id: 'C103', name: 'C103' },
    { id: 'C104', name: 'C104' },
    { id: 'C105', name: 'C105' },
    { id: 'C106', name: 'C106' },
    { id: 'C107', name: 'C107' },
    { id: 'C108', name: 'C108' },
    { id: 'C109', name: 'C109' },
    { id: 'C110', name: 'C110' },
    { id: 'C111', name: 'C111' },
    { id: 'C112', name: 'C112' },
    // QUCNDRCNTE C113 C C122
    { id: 'C113', name: 'C113' },
    { id: 'C114', name: 'C114' },
    { id: 'C115', name: 'C115' },
    { id: 'C116', name: 'C116' },
    { id: 'C117', name: 'C117' },
    { id: 'C118', name: 'C118' },
    { id: 'C119', name: 'C119' },
    { id: 'C120', name: 'C120' },
    { id: 'C121', name: 'C121' },
    { id: 'C122', name: 'C122' },

    // QUCNDRCNTE C201 C C212
    { id: 'C201', name: 'C201' },
    { id: 'C202', name: 'C202' },
    { id: 'C203', name: 'C203' },
    { id: 'C204', name: 'C204' },
    { id: 'C205', name: 'C205' },
    { id: 'C206', name: 'C206' },
    { id: 'C207', name: 'C207' },
    { id: 'C208', name: 'C208' },
    { id: 'C209', name: 'C209' },
    { id: 'C210', name: 'C210' },
    { id: 'C211', name: 'C211' },
    { id: 'C212', name: 'C212' },
    // QUCNDRCNTE C213 C C222
    { id: 'C213', name: 'C213' },
    { id: 'C214', name: 'C214' },
    { id: 'C215', name: 'C215' },
    { id: 'C216', name: 'C216' },
    { id: 'C217', name: 'C217' },
    { id: 'C218', name: 'C218' },
    { id: 'C219', name: 'C219' },
    { id: 'C220', name: 'C220' },
    { id: 'C221', name: 'C221' },
    { id: 'C222', name: 'C222' },

    // GALERIA DELTA
    { id: 'D201', name: 'D201' },
    { id: 'D202', name: 'D202' },
    { id: 'D203', name: 'D203' },
    { id: 'D204', name: 'D204' },
    { id: 'D205', name: 'D205' },
    { id: 'D206', name: 'D206' },
    { id: 'D207', name: 'D207' },
    { id: 'D208', name: 'D208' },
    { id: 'D209', name: 'D209' },
    { id: 'D210', name: 'D210' },

    { id: 'E201', name: 'E201' },
    { id: 'E202', name: 'E202' },
    { id: 'E203', name: 'E203' },
    { id: 'E204', name: 'E204' },

    { id: 'F201', name: 'F201' },
    { id: 'F202', name: 'F202' },
    { id: 'F203', name: 'F203' },
    { id: 'F204', name: 'F204' },
    //fora da unidade 
    { id: 'DOMICILIAR', name: 'DOMICILIAR' },
    { id: 'HOSPITAL', name: 'HOSPITAL' },
    { id: 'PERNOITE', name: 'PERNOITE' },
  ];
  //fim da listagem de select
  useEffect(() => {
    var data = startDate
    var dia = data.getDate();
    var mes = data.getMonth()
    var ano = data.getFullYear()



    switch (new Date().getMonth()) {
      case 0:
        mes = "01";
        break;
      case 1:
        mes = "02";
        break;
      case 2:
        mes = "03";
        break;
      case 3:
        mes = "04";
        break;
      case 4:
        mes = "05";
        break;
      case 5:
        mes = "06";
        break;

      case 6:
        mes = "07";
        break;

      case 7:
        mes = "08";
        break;

      case 8:
        mes = "09";
        break;

      case 9:
        mes = "10";
        break;

      case 10:
        mes = "11";
        break;
      case 11:
        mes = "12";
        break;
    };


    setSelectDate(dia + "/" + mes + "/" + ano)
  }, [startDate])


  useEffect(() => {
    if (galerias) {
      setNome(galerias.nome);
      setCela(galerias.cela);
      setOrigem(galerias.origem);
      setInfopen(galerias.infopen);
      setSelectDate(galerias.selectDate);
      setArtigo(galerias.artigo)
      setImgURL(galerias.imgURL)
      setSelecao(galerias.selecao)
      setVisita(galerias.visita)



    }
  }, [galerias]);

  if (props.celaMap && props.celaMap.length > 0) {
    // Ordenar o array com base em uma chave específica (por exemplo, 'nome')
    props.celaMap.sort((a, b) => {
      // Comparar os valores da chave 'nome' para ordenação alfabética
      return a.nome.localeCompare(b.nome);
    });

    // Agora, props.celaMap está ordenado em ordem alfabética com base no valor da chave 'nome'
  }

  return (
    <> {loading && <Loading></Loading>}
      {copied && <div style={{
        position: 'fixed',
        top: '90%',
        left: '92%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'green',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        zIndex: 9999, // Z-index alto para ficar na frente de todos os elementos
        display: copied ? 'block' : 'none',
      }}>
        COPIADO !
      </div>}
      <div className={`${styles.cela} ${sitIsolada ? styles.celaIsolada : ''}`}>
      <h5 style={{ 
    marginTop: 10, 
    marginBottom: 30, 
    fontFamily: 'Arial', 
    letterSpacing: 0.8, 
    display: 'flex', 
    justifyContent: 'center', 
    position: 'relative',
    width: '100%'
  }}>
   {!loading  && <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
      <strong>{props.cela}</strong> ( {props.celaTotal} )
    </span> }
    {tipoCelaAtual !== null && !sitIsolada && (
      <span style={{ position: 'absolute', right: 0, color: '#858484b0', marginRight:20 }}>
        {tipoCelaAtual}
      </span>
    )}
    {sitIsolada && (
      <span style={{ position: 'absolute', right: 0, marginRight:20 }}>
        ISOLADA
      </span>
    )}
  </h5>

        {props.usuario.includes("chefeseg") && <div style={{
          display: 'flex',
          alignItems: 'center', // Centraliza verticalmente
          padding: '5px',
          borderRadius: '8px',
          maxWidth: '1000px',
          margin: 'auto',
          marginBottom: 8
        }}>
          <input
            type="text"
            value={tipoCela} // Valor do input definido pelo estado
            onChange={handleInputChange} // Lidar com a mudança no input
            style={{
              width: 450,
              padding: '10px',
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '4px',
              fontSize: '18px',
              boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',
              marginRight: '15px',
              marginLeft: 220
            }}
            placeholder="Digite a classificação da cela..."
          />
          <button
            onClick={() => addDescricao(props.cela)}
            disabled={tipoCela === ''}
            style={{
              padding: '10px 20px',
              backgroundColor: '#a8b0b9',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              fontSize: '16px',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              transition: 'background-color 0.3s ease',
              marginRight: 10,


            }}
            
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#a8b0b9';
              e.currentTarget.style.cursor = 'not-allowed';
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'green';
              e.currentTarget.style.cursor = 'pointer';
            }}
          >
            <FaRegSave size={22} />
          </button>
          <button
            onClick={() => removeDescricao(props.cela)}
            disabled={tipoCelaAtual === null}
            style={{
              padding: '10px 20px',
              backgroundColor: '#a8b0b9',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              fontSize: '16px',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              transition: 'background-color 0.3s ease',

            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#a8b0b9';
              e.currentTarget.style.cursor = 'not-allowed';
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'green';
              e.currentTarget.style.cursor = 'pointer';
            }}
          >
            <MdOutlineCleaningServices size={22} />


          </button>
          {sitIsolada === false && (
            <button
              style={{
                padding: '10px 20px',
                backgroundColor: '#a8b0b9',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                fontSize: '16px',
                cursor: 'pointer',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s ease',
                marginLeft: 30
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'red'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#a8b0b9'}
              onClick={() => addIsolamento(props.cela)}
            >
              ISOLAR
            </button>
          )}
          {sitIsolada === true && (
            <button
              style={{
                padding: '10px 20px',
                backgroundColor: 'gray',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                fontSize: '16px',
                cursor: 'pointer',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s ease',
                marginLeft: 20
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#c82333'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#9d2222'}
              onClick={() => removeIsolamento(props.cela)}
            >
              LIBERAR
            </button>
          )}
        </div>}


        <MDBTable  >
          <thead style={{ fontSize: 12, fontFamily: 'Arial' }}>
            <th></th>
            <th></th>
            <th>Nome</th>
            <th className={styles.dataColumnTablet}>Entrada</th>
            {props.presoForaTitulo === 1 ? <th className={styles.dataColumnTablet}>Local</th> : <th className={styles.dataColumnTablet}>Origem</th>}
            <th className={styles.dataColumnTablet}>Artigo</th>
            <th className={styles.dataColumnTablet}>Infopen</th>
            <th className={styles.dataColumnTablet}>Uniforme</th>
            <th className={styles.dataColumnTablet}>Tipo</th>
            <th className={styles.dataColumnTablet}>Visita</th>
            {(props.usuario && !props.usuario.includes("vis")) && <th className={styles.dataColumnTablet}>Ações</th>}

          </thead>
          {props.celaMap &&
            props.celaMap.map((preso, index) => (

              <MDBTableBody key={index}>


                <Modal key={index} isOpen={modalDeleteAberto} presoIdDELETE={presoIdDELETE} presoNomeDELETE={presoNomeDELETE} presoUrlDELETE={presoUrlDELETE} presoEntradaDELETE={presoEntradaDELETE} presoCelaDELETE={presoCelaDELETE} presoOrigemDELETE={presoOrigemDELETE} presoInfopenDELETE={presoInfopenDELETE} toggle={toggleModalDelete} style={{ maxWidth: '920px', width: '100%' }}>


                  <ModalBody >


                    <div style={{ marginTop: 15 }}><center>
                      <p style={{ padding: 10, }}>

                        <h1><b>RETIRADA DE PRESOS </b></h1> </p>
                    </center></div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {/* Coluna da esquerda */}
                      <div style={{ marginRight: 20 }}>
                        {presoUrlDELETE ? (
                          // Se estiver definida, exibe a imagem
                          <img
                            alt={preso.nome}
                            height={280}
                            src={presoUrlDELETE}
                            style={{ marginBottom: 10 }}
                          />
                        ) : (
                          // Se não estiver definida, exibe uma mensagem alternativa ou um espaço reservado
                          <img
                            alt={preso.nome}
                            height={280}
                            src={userDef}
                            style={{ marginBottom: 10 }}
                          />
                        )}

                      </div>

                      {/* Coluna da direita */}
                      <div>
                        <div style={{ marginBottom: 20, fontSize: 30, fontWeight: 'bold', padding: 2, marginTop: 3 }}>{presoNomeDELETE}</div>
                        <div style={{ marginBottom: 10, fontSize: 22, padding: 2 }}>
                          <div style={{ marginBottom: 10, fontSize: 20, padding: 2, marginTop: 3 }}><b style={{ color: 'black' }}>CELA:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {presoCelaDELETE}</div>

                          <div style={{ display: 'flex', flexDirection: 'row', color: 'green', padding: 2, fontSize: 20 }}><b style={{ color: 'black' }}>ENTRADA:</b>&nbsp;&nbsp; {presoEntradaDELETE}</div> </div>
                        <div style={{ marginBottom: 10, fontSize: 20, color: 'red', padding: 2 }}><b style={{ color: 'black' }}>SAÍDA:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {dataFormatada}</div>
                        <select
                          onChange={handleChangeMotivo}
                          value={motivoSelecionado}
                          style={{ marginBottom: 10, fontSize: 20, width: '100%', marginTop: 20, padding: 2 }}
                        >
                          <option value="0">Selecione o Motivo...</option>
                          <option value="1">ALVARÁ</option>
                          <option value="2">TRANSFERÊNCIA</option>
                          <option value="3">OUTRO</option>
                        </select>

                      </div>
                    </div>


                  </ModalBody>
                  <ModalFooter style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <center><Button
                      disabled={disable}
                      style={{ backgroundColor: 'red', width: '100%', padding: 15, marginTop: 2 }}
                      onClick={() => handleDelete(presoIdDELETE, presoNomeDELETE, presoEntradaDELETE, presoCelaDELETE, presoUrlDELETE, presoInfopenDELETE, presoOrigemDELETE, usuarioLogado)}

                    ><b>REMOVER</b></Button></center>
                    <center><Button

                      style={{ backgroundColor: 'gray', width: '100%', padding: 15, marginTop: 2 }}
                      onClick={() => setModalDeleteAberto(!modalDeleteAberto)}

                    ><b>CANCELAR</b></Button></center>

                  </ModalFooter>

                </Modal>

                <tr onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}

                  style={{
                    borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: hoveredIndex === index ? '#f0f0f0' : 'white',
                    fontWeight: 'bold',
                    fontFamily: "Arial"
                  }} >
                  <td style={{
                    width: '30px', fontFamily: 'arial', borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
          ? '#8BC34A'  
          : hoveredIndex === index 
            ? '#f0f0f0'  
            : '#e0e0e0', 
                  }}><center>{index + 1}</center></td>
                  <td style={{
                    width: '40px', borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
          ? '#8BC34A'  
          : hoveredIndex === index 
            ? '#f0f0f0'  
            : '#e0e0e0', 
                  }}>
                    <img
                      alt={preso.nome}
                      height={70}
                      width={65}
                      src={preso.imgURL ? preso.imgURL : userDef}
                      onClick={() => toggleModal(preso.id)}
                      style={{
                        borderColor: hoveredIndex === index ? '#46464b' : 'black',
                        backgroundColor: hoveredIndex === index ? '#f0f0f0' : '#e0e0e0', cursor: 'pointer', border: '1px solid black', marginTop: -5, marginBottom: -5
                      }}
                    />
                  </td>
                  <td
      style={{
        cursor: 'pointer',
        borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
        color: hoveredIndex === index ? 'black' : 'rgb(46, 44, 44)',
        backgroundColor: nomeSetado === preso.nome 
          ? '#8BC34A'  
          : hoveredIndex === index 
            ? '#f0f0f0'  
            : '#e0e0e0', 
        width: '400px',
        letterSpacing: 1,
        textAlign: 'left',
        paddingLeft: 30,
      }}
      onClick={() => copyToClipboard(preso.nome)}
      className={styles.dataColumnTabletNome}
    >
      {preso.nome}
    </td>
                  <td style={{
                    borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
                    ? '#8BC34A'  
                    : hoveredIndex === index 
                      ? '#f0f0f0'  
                      : '#e0e0e0', 
                      width: '60px'
                  }} className={styles.dataColumnTablet}><small>{preso.selectDate}</small></td>
                  {preso.localFora && <td style={{
                    borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
                    ? '#8BC34A'  
                    : hoveredIndex === index 
                      ? '#f0f0f0'  
                      : '#e0e0e0',  width: '100px'
                  }} className={styles.dataColumnTablet}>{preso.localFora}</td>}
                  {!preso.localFora && <td style={{
                    borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
          ? '#8BC34A'  
          : hoveredIndex === index 
            ? '#f0f0f0'  
            : '#e0e0e0',  width: '80px'
                  }} className={styles.dataColumnTablet}><small>{preso.origem}</small></td>}
                  <td style={{
                    borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
                    ? '#8BC34A'  
                    : hoveredIndex === index 
                      ? '#f0f0f0'  
                      : '#e0e0e0',  width: '140px', fontSize: 13
                  }} className={styles.dataColumnTablet}><small>{preso.artigo}</small></td>
                  <td style={{
                    borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
          ? '#8BC34A'  
          : hoveredIndex === index 
            ? '#f0f0f0'  
            : '#e0e0e0',  width: '100px', alignItems: 'center', marginLeft: 2, fontSize: 14
                  }} className={styles.dataColumnTablet}>
                    <small>{preso.infopen} </small>
                    <a href={'https://infopen.sejus.es.gov.br/Identificacao/Presos.aspx?ID=' + preso.infopen} target="_blank" rel="noreferrer">
                      {preso.infopen && <FaEye style={{ color: 'green' }} size={22} />}
                    </a>
                  </td>
                  <td style={{
                    borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
          ? '#8BC34A'  
          : hoveredIndex === index 
            ? '#f0f0f0'  
            : '#e0e0e0',  width: '100px', alignItems: 'center', marginLeft: 2, fontSize: 14
                  }} className={styles.dataColumnTablet}>
                    <small>{preso.un} </small>
                    
                  </td>
                  <td style={{
                    width: '60px', fontSize: 13, borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
                    ? '#8BC34A'  
                    : hoveredIndex === index 
                      ? '#f0f0f0'  
                      : '#e0e0e0', 
                  }} className={styles.dataColumnTablet}><small>{preso.selecao}</small></td>
                  <td style={{
                    width: '80px', fontSize: 13, borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                    backgroundColor: nomeSetado === preso.nome 
                    ? '#8BC34A'  
                    : hoveredIndex === index 
                      ? '#f0f0f0'  
                      : '#e0e0e0', 
                  }} className={styles.dataColumnTablet}>
                    <small> </small>
                    <a href={'https://diasdevisitas.com.br/resultado2.php?digitado=' + preso.infopen} target="_blank" rel="noreferrer">
                      <FaEye style={{ color: 'green' }} size={22} />
                    </a>
                  </td>
                  {(props.usuario && !props.usuario.includes("vis")) &&
                    <td style={{
                      width: '100px', verticalAlign: 'middle', borderColor: hoveredIndex === index ? '#46464b' : '#ddd',
                      backgroundColor: nomeSetado === preso.nome 
          ? '#8BC34A'  
          : hoveredIndex === index 
            ? '#f0f0f0'  
            : '#e0e0e0', 
                    }} className={styles.dataColumnTablet}>
                      <FaRegEdit
                        color="#5c6051"
                        size={24}
                        onClick={() => toggleModal(preso.id)}
                        style={{ cursor: 'pointer' }}
                        className={styles.iconHoverEffect} // Adiciona uma classe para aplicar o efeito de hover
                      />
                      &nbsp;&nbsp;
                      <ImUserMinus
                        color="#5c6051"
                        size={24}
                        style={{ cursor: 'pointer' }}
                        onClick={() => toggleModalDelete(preso.id, preso.nome, preso.selectDate, preso.imgURL, preso.cela, preso.origem, preso.infopen)}
                        className={styles.iconHoverEffect} // Adiciona uma classe para aplicar o efeito de hover
                      />
                    </td>
                  }

                </tr>
              </MDBTableBody>
            ))}
        </MDBTable>{' '}
        {props.celaTotal === 0 && <center><h5 style={{ color: "gray", padding: 30 }}>CELA VAZIA</h5></center>}
      </div>



      {!loading && <Modal isOpen={modalIsOpen} toggle={toggleModal} style={{ maxWidth: '1200px', width: '100%', backgroundColor: '#4b4e41' }}>
        <ModalBody>
          <>

            <div style={{ color: 'white', borderRadius: 10, backgroundColor: '#4b4e41', width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}><center>
              <div style={{ fontFamily: 'policiapenal' }}><center> <p style={{ fontFamily: 'policiapenal', padding: 10, color: '#8a9175' }}><h1>REGISTRO</h1></p> </center></div>
            </center>
              <Form onSubmit={handleSubmit} >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {uploadedImage ? (
                    <center><img style={{ fontSize: 18, color: '#8a9175', marginBottom: 15, marginTop: 20 }} src={uploadedImage} height={250} alt="Imagem carregada" /></center>
                  ) : (
                    <div><center> <br></br><div>

                      {!imgURL && <img alt='Foto Preso' src={userDef}></img>}
                      {imgURL && <center><img height={250} alt="PPES" src={imgURL} style={{ fontSize: 18, color: '#8a9175', marginBottom: 15, marginTop: -10 }} /></center>}</div></center><center><br></br><p style={{ fontSize: 18, color: '#8a9175', marginBottom: 20, marginTop: 20 }}>Arraste e solte uma imagem ou clique para selecionar o arquivo.</p></center></div>
                  )}
                </div><br></br>

                <FormGroup>

                  <Label for="exampleEmail">
                    <p style={{ color: '#8a9175', marginBottom: -3, }}><b>NOME</b></p>
                  </Label>
                  <Input
                    id="nome"
                    name="nome"
                    placeholder="Nome completo"
                    type="text"
                    required
                    value={nome}
                    onChange={(e) => { setNome(e.target.value.toUpperCase()) }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="examplePassword">
                    <p style={{ color: '#8a9175', marginBottom: -3, }}><b>ORIGEM</b></p>
                  </Label>
                  <Input
                    id="origem"
                    name="origem"
                    placeholder="Origem do recebimento"
                    type="text"
                    required
                    value={origem}
                    onChange={(e) => { setOrigem(e.target.value.toUpperCase()) }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleSelect">
                    <p style={{ color: '#8a9175', marginBottom: -3, }}><b>CELA DE DESTINO ({cela})</b></p>
                  </Label>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={cela}
                    onChange={handleChange}
                  >
                    {list.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))}
                  </Input>

                </FormGroup>
                <FormGroup>
                  <Label for="exampleSelectMulti">
                    <p style={{ color: '#8a9175', marginBottom: -3, }}><b>DATA DA ENTRADA </b></p>
                  </Label>
                  <Input
                    id="origem"
                    name="origem"
                    placeholder="Origem do recebimento"

                    required
                    value={selectDate}
                    style={{ border: '2px solid #253221' }}
                    onChange={(e) => setSelectDate(e.target.value)}
                  />
                </FormGroup><br></br>
                <center><small><p style={{ color: '#8a9175', marginBottom: -3, }}>INFORMAÇÕES COMPLEMENTARES</p></small> </center>
                <FormGroup>
                <FormGroup>
              <Label for="examplePassword">
                <p style={{ color: '#8a9175', marginBottom: -3, }}><b>UNIFORME</b></p>
              </Label>
              <Input
                id="infopen"
                name="infopen"
                placeholder="Número do Uniforme"
                type="number"
                style={{ border: '2px solid #253221' }}
                value={un}
                onChange={(e) => { setUn(e.target.value.toUpperCase()) }}
              />
            </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                      <p style={{ color: '#8a9175', marginBottom: -3, }}><b>INFOPEN</b></p>
                    </Label>
                    <Input
                      id="infopen"
                      name="infopen"
                      placeholder="Número do INFOPEN"
                      type="number"
                      value={infopen}
                      onChange={(e) => { setInfopen(e.target.value.toUpperCase()) }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                      <p style={{ color: '#8a9175', marginBottom: -3, }}><b>ARTIGO</b></p>
                    </Label>
                    <Input
                      id="artigo"
                      name="artigo"
                      placeholder="Artigo"
                      type="text"
                      value={artigo}
                      onChange={(e) => { setArtigo(e.target.value.toUpperCase()) }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">
                      <p style={{ color: '#8a9175', marginBottom: -3, }}> <b>TIPO DE CRIME</b></p>
                    </Label>
                    <Input type="select" name="selecao" id="selecao" value={selecao}
                      onChange={(e) => { setSelecao(e.target.value) }}>
                      <option value=""></option>
                      <option value="PRIM.">PRIMÁRIO</option>
                      <option value="REIN.">REINCIDENTE</option>
                    </Input>
                  </FormGroup>
                 
                </FormGroup>
                <br></br>
                <center><Link to="/"><Button style={{ backgroundColor: 'GRAY', color: 'white', fontFamily: 'policiapenal', fontSize: 14, padding: 14, marginBottom: 30 }} onClick={toggleModal}>
                  FECHAR
                </Button></Link>&nbsp;{(props.usuario && !props.usuario.includes("vis")) &&
                  <>
                    <Button
                      className={styles.dataColumnTablet}
                      style={{
                        backgroundColor: '#8a9175',
                        color: 'white',
                        fontFamily: 'policiapenal',
                        fontSize: 14,
                        padding: 14,
                        marginBottom: 30,
                      }}

                      disabled={botaoClicado} // Desativa o botão se botaoClicado for verdadeiro
                    >
                      GRAVAR
                    </Button>
                  </>}</center><br></br>
              </Form></div></>
        </ModalBody>

      </Modal>}
    </>
  );
};

export default Cela;
