import { useEffect, useState, useRef } from 'react';
import styles from './Home.module.css'
import Cela from '../../components/Cela'
import { Table } from 'reactstrap';
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import logo from '../../assets/img/logo.png'
import { FaSearch } from "react-icons/fa";
import userDef from '../../assets/img/User-Default3.jpg'
import { FaEye } from "react-icons/fa";
import Loading from '../../components/Loading';
import axios from 'axios'
import { Button, Skeleton, styled } from '@mui/material';


const ShinySkeleton = styled(Skeleton)(({ theme }) => ({
  background: 'linear-gradient(90deg, rgba(255,255,255,0.05) 25%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.05) 75%)',
  backgroundSize: '200% 100%',
  animation: 'shine 1.5s infinite',
  '@keyframes shine': {
    '0%': {
      backgroundPosition: '-200% 0',
    },
    '100%': {
      backgroundPosition: '200% 0',
    },
  },
}));

const Home = ({ userVerificacao, galerias }) => {
  const [pagina, setPagina] = useState(1)
  const [pessoasDomiciliar, setPessoasDomiciliar] = useState('')
  const [pessoasHospital, setPessoasHospital] = useState('')
  const [pessoasPernoite, setPessoasPernoite] = useState('')
  const [modalBuscaAberto, setModalBuscaAberto] = useState(false);
  const [termoBusca, setTermoBusca] = useState('');
  const [resultadosBusca, setResultadosBusca] = useState([]);
  const [quadranteA, setQuadranteA] = useState(1)
  const [quadranteB, setQuadranteB] = useState(1)
  const [quadranteC, setQuadranteC] = useState(1)
  const [ativaBotao, setAtivaBotao] = useState(1)
  const [usuario] = useState(userVerificacao)
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false)
  const [isolamento, setIsolamento] = useState([])
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [nomeBuscado, setNomeBuscado] = useState('')

  const inputBuscaRef = useRef(null);

  useEffect(() => {
    if (modalBuscaAberto) {
      const timer = setTimeout(() => {
        inputBuscaRef.current.focus();
      }, 500);

      // Limpar o timer quando o componente for desmontado ou se modalBuscaAberto mudar
      return () => clearTimeout(timer);
    }
  }, [modalBuscaAberto]);

  const addnomebuscado = (nome) => {

    setNomeBuscado(nome)

  }




  const scrollToCoordinates = (cela) => {
    const targetElement = document.getElementById(cela);

    if (targetElement) {
      // Rolar até o elemento
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleCliqueBuscaCela = (cela, nome) => {
    setNomeBuscado(nome)
    const primeiraLetra = cela.charAt(0);
    const segundaletra = cela.charAt(1);

    if (primeiraLetra == 'A') {

      paginaGaleriaA()
      if (segundaletra == 1) {
        setQuadranteA(1)
      } else {
        setQuadranteA(2)
      }
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);


    }
    if (primeiraLetra == 'B') {
      paginaGaleriaB()
      if (segundaletra == 1) {
        setQuadranteB(1)
      } else {
        setQuadranteB(2)
      }
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'C') {
      paginaGaleriaC()
      if (segundaletra == 1) {
        setQuadranteC(1)
      } else {
        setQuadranteC(2)
      }
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'D') {
      paginaGaleriaD()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'E') {
      paginaGaleriaE()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'F') {
      paginaGaleriaF()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }

    if (segundaletra == 'O') {
      paginaForaUP()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'P') {
      paginaForaUP()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
  };


  const toggleModalBusca = () => {
    setTermoBusca('')
    setModalBuscaAberto(!modalBuscaAberto);
    setResultadosBusca([])
  };


  useEffect(() => {
    if (termoBusca === "SEM INFOPEN") {
      // Caso o termo de busca seja "SEM INFOPEN", filtre os registros onde infopen é 0
      setResultadosBusca(galerias.filter(item => item.infopen == 0));
      return; // Retorne para evitar a execução do restante do código
    }

    if (termoBusca.length < 3 || !galerias) {
      setResultadosBusca([]);
      return; // Retorne para evitar a execução do restante do código
    }

    // O restante do código permanece inalterado
    const termoNumerico = parseInt(termoBusca);

    const resultados = galerias.filter(item => {
      const infopenStr = item.infopen; // Converte infopen para string

      return (
        (typeof item.nome === 'string' && item.nome.includes(termoBusca)) ||
        (typeof item.selectDate === 'string' && item.selectDate.includes(termoBusca)) ||
        (typeof item.cela === 'string' && item.cela.includes(termoBusca)) ||
        (typeof item.tipo === 'string' && item.tipo.includes(termoBusca)) ||
        (typeof item.artigo === 'string' && item.artigo.includes(termoBusca)) ||
        (typeof item.selecao === 'string' && item.selecao.includes(termoBusca)) ||
        (typeof item.visita === 'string' && item.visita.includes(termoBusca)) ||
        (typeof item.infopen === 'string' && infopenStr.includes(termoBusca)) || // Busca por string
        (typeof item.infopen === 'number' && item.infopen === termoNumerico) // Busca por número
      );
    });

    // Atualizar os resultados da busca
    setResultadosBusca(resultados);
  }, [galerias, termoBusca]);


  // criando componente que vai até o topo do quarante com o botão


  const handleButtonClickA101 = () => {
    setQuadranteA(1)
  };

  const handleButtonClickA113 = () => {
    setQuadranteA(2)
  };


  // GALERIA B DIVISAO 
  const handleButtonClickB101 = () => {
    setQuadranteB(1)
  };

  const handleButtonClickB113 = () => {
    setQuadranteB(2)
  };

  // GALERIA C DIVISAO 
  const handleButtonClickC101 = () => {
    setQuadranteC(1)
  };

  const handleButtonClickC113 = () => {
    setQuadranteC(2)

  };

  // criação das variáveis MAP GALERIA A
  const [pessoasSearchMapA101, setPessoasSearchMapA101] = useState('')
  const [pessoasSearchMapA102, setPessoasSearchMapA102] = useState('')
  const [pessoasSearchMapA103, setPessoasSearchMapA103] = useState('')
  const [pessoasSearchMapA104, setPessoasSearchMapA104] = useState('')
  const [pessoasSearchMapA105, setPessoasSearchMapA105] = useState('')
  const [pessoasSearchMapA106, setPessoasSearchMapA106] = useState('')
  const [pessoasSearchMapA107, setPessoasSearchMapA107] = useState('')
  const [pessoasSearchMapA108, setPessoasSearchMapA108] = useState('')
  const [pessoasSearchMapA109, setPessoasSearchMapA109] = useState('')
  const [pessoasSearchMapA110, setPessoasSearchMapA110] = useState('')
  const [pessoasSearchMapA111, setPessoasSearchMapA111] = useState('')
  const [pessoasSearchMapA112, setPessoasSearchMapA112] = useState('')
  const [pessoasSearchMapA113, setPessoasSearchMapA113] = useState('')
  const [pessoasSearchMapA114, setPessoasSearchMapA114] = useState('')
  const [pessoasSearchMapA115, setPessoasSearchMapA115] = useState('')
  const [pessoasSearchMapA116, setPessoasSearchMapA116] = useState('')
  const [pessoasSearchMapA117, setPessoasSearchMapA117] = useState('')
  const [pessoasSearchMapA118, setPessoasSearchMapA118] = useState('')
  const [pessoasSearchMapA119, setPessoasSearchMapA119] = useState('')
  const [pessoasSearchMapA120, setPessoasSearchMapA120] = useState('')
  const [pessoasSearchMapA121, setPessoasSearchMapA121] = useState('')
  const [pessoasSearchMapA122, setPessoasSearchMapA122] = useState('')
  const [pessoasSearchMapA201, setPessoasSearchMapA201] = useState('')
  const [pessoasSearchMapA202, setPessoasSearchMapA202] = useState('')
  const [pessoasSearchMapA203, setPessoasSearchMapA203] = useState('')
  const [pessoasSearchMapA204, setPessoasSearchMapA204] = useState('')
  const [pessoasSearchMapA205, setPessoasSearchMapA205] = useState('')
  const [pessoasSearchMapA206, setPessoasSearchMapA206] = useState('')
  const [pessoasSearchMapA207, setPessoasSearchMapA207] = useState('')
  const [pessoasSearchMapA208, setPessoasSearchMapA208] = useState('')
  const [pessoasSearchMapA209, setPessoasSearchMapA209] = useState('')
  const [pessoasSearchMapA210, setPessoasSearchMapA210] = useState('')
  const [pessoasSearchMapA211, setPessoasSearchMapA211] = useState('')
  const [pessoasSearchMapA212, setPessoasSearchMapA212] = useState('')
  const [pessoasSearchMapA213, setPessoasSearchMapA213] = useState('')
  const [pessoasSearchMapA214, setPessoasSearchMapA214] = useState('')
  const [pessoasSearchMapA215, setPessoasSearchMapA215] = useState('')
  const [pessoasSearchMapA216, setPessoasSearchMapA216] = useState('')
  const [pessoasSearchMapA217, setPessoasSearchMapA217] = useState('')
  const [pessoasSearchMapA218, setPessoasSearchMapA218] = useState('')
  const [pessoasSearchMapA219, setPessoasSearchMapA219] = useState('')
  const [pessoasSearchMapA220, setPessoasSearchMapA220] = useState('')
  const [pessoasSearchMapA221, setPessoasSearchMapA221] = useState('')
  const [pessoasSearchMapA222, setPessoasSearchMapA222] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB101, setPessoasSearchMapB101] = useState('')
  const [pessoasSearchMapB102, setPessoasSearchMapB102] = useState('')
  const [pessoasSearchMapB103, setPessoasSearchMapB103] = useState('')
  const [pessoasSearchMapB104, setPessoasSearchMapB104] = useState('')
  const [pessoasSearchMapB105, setPessoasSearchMapB105] = useState('')
  const [pessoasSearchMapB106, setPessoasSearchMapB106] = useState('')
  const [pessoasSearchMapB107, setPessoasSearchMapB107] = useState('')
  const [pessoasSearchMapB108, setPessoasSearchMapB108] = useState('')
  const [pessoasSearchMapB109, setPessoasSearchMapB109] = useState('')
  const [pessoasSearchMapB110, setPessoasSearchMapB110] = useState('')
  const [pessoasSearchMapB111, setPessoasSearchMapB111] = useState('')
  const [pessoasSearchMapB112, setPessoasSearchMapB112] = useState('')
  const [pessoasSearchMapB113, setPessoasSearchMapB113] = useState('')
  const [pessoasSearchMapB114, setPessoasSearchMapB114] = useState('')
  const [pessoasSearchMapB115, setPessoasSearchMapB115] = useState('')
  const [pessoasSearchMapB116, setPessoasSearchMapB116] = useState('')
  const [pessoasSearchMapB117, setPessoasSearchMapB117] = useState('')
  const [pessoasSearchMapB118, setPessoasSearchMapB118] = useState('')
  const [pessoasSearchMapB119, setPessoasSearchMapB119] = useState('')
  const [pessoasSearchMapB120, setPessoasSearchMapB120] = useState('')
  const [pessoasSearchMapB121, setPessoasSearchMapB121] = useState('')
  const [pessoasSearchMapB122, setPessoasSearchMapB122] = useState('')
  const [pessoasSearchMapB201, setPessoasSearchMapB201] = useState('')
  const [pessoasSearchMapB202, setPessoasSearchMapB202] = useState('')
  const [pessoasSearchMapB203, setPessoasSearchMapB203] = useState('')
  const [pessoasSearchMapB204, setPessoasSearchMapB204] = useState('')
  const [pessoasSearchMapB205, setPessoasSearchMapB205] = useState('')
  const [pessoasSearchMapB206, setPessoasSearchMapB206] = useState('')
  const [pessoasSearchMapB207, setPessoasSearchMapB207] = useState('')
  const [pessoasSearchMapB208, setPessoasSearchMapB208] = useState('')
  const [pessoasSearchMapB209, setPessoasSearchMapB209] = useState('')
  const [pessoasSearchMapB210, setPessoasSearchMapB210] = useState('')
  const [pessoasSearchMapB211, setPessoasSearchMapB211] = useState('')
  const [pessoasSearchMapB212, setPessoasSearchMapB212] = useState('')
  const [pessoasSearchMapB213, setPessoasSearchMapB213] = useState('')
  const [pessoasSearchMapB214, setPessoasSearchMapB214] = useState('')
  const [pessoasSearchMapB215, setPessoasSearchMapB215] = useState('')
  const [pessoasSearchMapB216, setPessoasSearchMapB216] = useState('')
  const [pessoasSearchMapB217, setPessoasSearchMapB217] = useState('')
  const [pessoasSearchMapB218, setPessoasSearchMapB218] = useState('')
  const [pessoasSearchMapB219, setPessoasSearchMapB219] = useState('')
  const [pessoasSearchMapB220, setPessoasSearchMapB220] = useState('')
  const [pessoasSearchMapB221, setPessoasSearchMapB221] = useState('')
  const [pessoasSearchMapB222, setPessoasSearchMapB222] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC101, setPessoasSearchMapC101] = useState('')
  const [pessoasSearchMapC102, setPessoasSearchMapC102] = useState('')
  const [pessoasSearchMapC103, setPessoasSearchMapC103] = useState('')
  const [pessoasSearchMapC104, setPessoasSearchMapC104] = useState('')
  const [pessoasSearchMapC105, setPessoasSearchMapC105] = useState('')
  const [pessoasSearchMapC106, setPessoasSearchMapC106] = useState('')
  const [pessoasSearchMapC107, setPessoasSearchMapC107] = useState('')
  const [pessoasSearchMapC108, setPessoasSearchMapC108] = useState('')
  const [pessoasSearchMapC109, setPessoasSearchMapC109] = useState('')
  const [pessoasSearchMapC110, setPessoasSearchMapC110] = useState('')
  const [pessoasSearchMapC111, setPessoasSearchMapC111] = useState('')
  const [pessoasSearchMapC112, setPessoasSearchMapC112] = useState('')
  const [pessoasSearchMapC113, setPessoasSearchMapC113] = useState('')
  const [pessoasSearchMapC114, setPessoasSearchMapC114] = useState('')
  const [pessoasSearchMapC115, setPessoasSearchMapC115] = useState('')
  const [pessoasSearchMapC116, setPessoasSearchMapC116] = useState('')
  const [pessoasSearchMapC117, setPessoasSearchMapC117] = useState('')
  const [pessoasSearchMapC118, setPessoasSearchMapC118] = useState('')
  const [pessoasSearchMapC119, setPessoasSearchMapC119] = useState('')
  const [pessoasSearchMapC120, setPessoasSearchMapC120] = useState('')
  const [pessoasSearchMapC121, setPessoasSearchMapC121] = useState('')
  const [pessoasSearchMapC122, setPessoasSearchMapC122] = useState('')
  const [pessoasSearchMapC201, setPessoasSearchMapC201] = useState('')
  const [pessoasSearchMapC202, setPessoasSearchMapC202] = useState('')
  const [pessoasSearchMapC203, setPessoasSearchMapC203] = useState('')
  const [pessoasSearchMapC204, setPessoasSearchMapC204] = useState('')
  const [pessoasSearchMapC205, setPessoasSearchMapC205] = useState('')
  const [pessoasSearchMapC206, setPessoasSearchMapC206] = useState('')
  const [pessoasSearchMapC207, setPessoasSearchMapC207] = useState('')
  const [pessoasSearchMapC208, setPessoasSearchMapC208] = useState('')
  const [pessoasSearchMapC209, setPessoasSearchMapC209] = useState('')
  const [pessoasSearchMapC210, setPessoasSearchMapC210] = useState('')
  const [pessoasSearchMapC211, setPessoasSearchMapC211] = useState('')
  const [pessoasSearchMapC212, setPessoasSearchMapC212] = useState('')
  const [pessoasSearchMapC213, setPessoasSearchMapC213] = useState('')
  const [pessoasSearchMapC214, setPessoasSearchMapC214] = useState('')
  const [pessoasSearchMapC215, setPessoasSearchMapC215] = useState('')
  const [pessoasSearchMapC216, setPessoasSearchMapC216] = useState('')
  const [pessoasSearchMapC217, setPessoasSearchMapC217] = useState('')
  const [pessoasSearchMapC218, setPessoasSearchMapC218] = useState('')
  const [pessoasSearchMapC219, setPessoasSearchMapC219] = useState('')
  const [pessoasSearchMapC220, setPessoasSearchMapC220] = useState('')
  const [pessoasSearchMapC221, setPessoasSearchMapC221] = useState('')
  const [pessoasSearchMapC222, setPessoasSearchMapC222] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapD201, setPessoasSearchMapD201] = useState('')
  const [pessoasSearchMapD202, setPessoasSearchMapD202] = useState('')
  const [pessoasSearchMapD203, setPessoasSearchMapD203] = useState('')
  const [pessoasSearchMapD204, setPessoasSearchMapD204] = useState('')
  const [pessoasSearchMapD205, setPessoasSearchMapD205] = useState('')
  const [pessoasSearchMapD206, setPessoasSearchMapD206] = useState('')
  const [pessoasSearchMapD207, setPessoasSearchMapD207] = useState('')
  const [pessoasSearchMapD208, setPessoasSearchMapD208] = useState('')
  const [pessoasSearchMapD209, setPessoasSearchMapD209] = useState('')
  const [pessoasSearchMapD210, setPessoasSearchMapD210] = useState('')


  // GALERIA E

  const [pessoasSearchMapE201, setPessoasSearchMapE201] = useState('')
  const [pessoasSearchMapE202, setPessoasSearchMapE202] = useState('')
  const [pessoasSearchMapE203, setPessoasSearchMapE203] = useState('')
  const [pessoasSearchMapE204, setPessoasSearchMapE204] = useState('')

  // GALERIA F

  const [pessoasSearchMapF201, setPessoasSearchMapF201] = useState('')
  const [pessoasSearchMapF202, setPessoasSearchMapF202] = useState('')
  const [pessoasSearchMapF203, setPessoasSearchMapF203] = useState('')
  const [pessoasSearchMapF204, setPessoasSearchMapF204] = useState('')

  //variaveis da triagem

  const [pessoasSearchMapTR201, setPessoasSearchMapTR201] = useState('')
  const [pessoasSearchMapTR202, setPessoasSearchMapTR202] = useState('')





  //CRIAÇÃO DAS VARIÁVEIS DE PRESOS FORA DA UNIDADE

  const [pessoasDomiciliartotal, setPessoasDomiciliartotal] = useState('')
  const [pessoasHospitaltotal, setPessoasHospitaltotal] = useState('')
  const [pessoasPernoitetotal, setPessoasPernoitetotal] = useState('')



  // criação das variáveis de totalização do componente Cela
  const [pessoasSearchMapA101total, setPessoasSearchMapA101total] = useState('')
  const [pessoasSearchMapA102total, setPessoasSearchMapA102total] = useState('')
  const [pessoasSearchMapA103total, setPessoasSearchMapA103total] = useState('')
  const [pessoasSearchMapA104total, setPessoasSearchMapA104total] = useState('')
  const [pessoasSearchMapA105total, setPessoasSearchMapA105total] = useState('')
  const [pessoasSearchMapA106total, setPessoasSearchMapA106total] = useState('')
  const [pessoasSearchMapA107total, setPessoasSearchMapA107total] = useState('')
  const [pessoasSearchMapA108total, setPessoasSearchMapA108total] = useState('')
  const [pessoasSearchMapA109total, setPessoasSearchMapA109total] = useState('')
  const [pessoasSearchMapA110total, setPessoasSearchMapA110total] = useState('')
  const [pessoasSearchMapA111total, setPessoasSearchMapA111total] = useState('')
  const [pessoasSearchMapA112total, setPessoasSearchMapA112total] = useState('')
  const [pessoasSearchMapA113total, setPessoasSearchMapA113total] = useState('')
  const [pessoasSearchMapA114total, setPessoasSearchMapA114total] = useState('')
  const [pessoasSearchMapA115total, setPessoasSearchMapA115total] = useState('')
  const [pessoasSearchMapA116total, setPessoasSearchMapA116total] = useState('')
  const [pessoasSearchMapA117total, setPessoasSearchMapA117total] = useState('')
  const [pessoasSearchMapA118total, setPessoasSearchMapA118total] = useState('')
  const [pessoasSearchMapA119total, setPessoasSearchMapA119total] = useState('')
  const [pessoasSearchMapA120total, setPessoasSearchMapA120total] = useState('')
  const [pessoasSearchMapA121total, setPessoasSearchMapA121total] = useState('')
  const [pessoasSearchMapA122total, setPessoasSearchMapA122total] = useState('')
  const [pessoasSearchMapA201total, setPessoasSearchMapA201total] = useState('')
  const [pessoasSearchMapA202total, setPessoasSearchMapA202total] = useState('')
  const [pessoasSearchMapA203total, setPessoasSearchMapA203total] = useState('')
  const [pessoasSearchMapA204total, setPessoasSearchMapA204total] = useState('')
  const [pessoasSearchMapA205total, setPessoasSearchMapA205total] = useState('')
  const [pessoasSearchMapA206total, setPessoasSearchMapA206total] = useState('')
  const [pessoasSearchMapA207total, setPessoasSearchMapA207total] = useState('')
  const [pessoasSearchMapA208total, setPessoasSearchMapA208total] = useState('')
  const [pessoasSearchMapA209total, setPessoasSearchMapA209total] = useState('')
  const [pessoasSearchMapA210total, setPessoasSearchMapA210total] = useState('')
  const [pessoasSearchMapA211total, setPessoasSearchMapA211total] = useState('')
  const [pessoasSearchMapA212total, setPessoasSearchMapA212total] = useState('')
  const [pessoasSearchMapA213total, setPessoasSearchMapA213total] = useState('')
  const [pessoasSearchMapA214total, setPessoasSearchMapA214total] = useState('')
  const [pessoasSearchMapA215total, setPessoasSearchMapA215total] = useState('')
  const [pessoasSearchMapA216total, setPessoasSearchMapA216total] = useState('')
  const [pessoasSearchMapA217total, setPessoasSearchMapA217total] = useState('')
  const [pessoasSearchMapA218total, setPessoasSearchMapA218total] = useState('')
  const [pessoasSearchMapA219total, setPessoasSearchMapA219total] = useState('')
  const [pessoasSearchMapA220total, setPessoasSearchMapA220total] = useState('')
  const [pessoasSearchMapA221total, setPessoasSearchMapA221total] = useState('')
  const [pessoasSearchMapA222total, setPessoasSearchMapA222total] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB101total, setPessoasSearchMapB101total] = useState('')
  const [pessoasSearchMapB102total, setPessoasSearchMapB102total] = useState('')
  const [pessoasSearchMapB103total, setPessoasSearchMapB103total] = useState('')
  const [pessoasSearchMapB104total, setPessoasSearchMapB104total] = useState('')
  const [pessoasSearchMapB105total, setPessoasSearchMapB105total] = useState('')
  const [pessoasSearchMapB106total, setPessoasSearchMapB106total] = useState('')
  const [pessoasSearchMapB107total, setPessoasSearchMapB107total] = useState('')
  const [pessoasSearchMapB108total, setPessoasSearchMapB108total] = useState('')
  const [pessoasSearchMapB109total, setPessoasSearchMapB109total] = useState('')
  const [pessoasSearchMapB110total, setPessoasSearchMapB110total] = useState('')
  const [pessoasSearchMapB111total, setPessoasSearchMapB111total] = useState('')
  const [pessoasSearchMapB112total, setPessoasSearchMapB112total] = useState('')
  const [pessoasSearchMapB113total, setPessoasSearchMapB113total] = useState('')
  const [pessoasSearchMapB114total, setPessoasSearchMapB114total] = useState('')
  const [pessoasSearchMapB115total, setPessoasSearchMapB115total] = useState('')
  const [pessoasSearchMapB116total, setPessoasSearchMapB116total] = useState('')
  const [pessoasSearchMapB117total, setPessoasSearchMapB117total] = useState('')
  const [pessoasSearchMapB118total, setPessoasSearchMapB118total] = useState('')
  const [pessoasSearchMapB119total, setPessoasSearchMapB119total] = useState('')
  const [pessoasSearchMapB120total, setPessoasSearchMapB120total] = useState('')
  const [pessoasSearchMapB121total, setPessoasSearchMapB121total] = useState('')
  const [pessoasSearchMapB122total, setPessoasSearchMapB122total] = useState('')
  const [pessoasSearchMapB201total, setPessoasSearchMapB201total] = useState('')
  const [pessoasSearchMapB202total, setPessoasSearchMapB202total] = useState('')
  const [pessoasSearchMapB203total, setPessoasSearchMapB203total] = useState('')
  const [pessoasSearchMapB204total, setPessoasSearchMapB204total] = useState('')
  const [pessoasSearchMapB205total, setPessoasSearchMapB205total] = useState('')
  const [pessoasSearchMapB206total, setPessoasSearchMapB206total] = useState('')
  const [pessoasSearchMapB207total, setPessoasSearchMapB207total] = useState('')
  const [pessoasSearchMapB208total, setPessoasSearchMapB208total] = useState('')
  const [pessoasSearchMapB209total, setPessoasSearchMapB209total] = useState('')
  const [pessoasSearchMapB210total, setPessoasSearchMapB210total] = useState('')
  const [pessoasSearchMapB211total, setPessoasSearchMapB211total] = useState('')
  const [pessoasSearchMapB212total, setPessoasSearchMapB212total] = useState('')
  const [pessoasSearchMapB213total, setPessoasSearchMapB213total] = useState('')
  const [pessoasSearchMapB214total, setPessoasSearchMapB214total] = useState('')
  const [pessoasSearchMapB215total, setPessoasSearchMapB215total] = useState('')
  const [pessoasSearchMapB216total, setPessoasSearchMapB216total] = useState('')
  const [pessoasSearchMapB217total, setPessoasSearchMapB217total] = useState('')
  const [pessoasSearchMapB218total, setPessoasSearchMapB218total] = useState('')
  const [pessoasSearchMapB219total, setPessoasSearchMapB219total] = useState('')
  const [pessoasSearchMapB220total, setPessoasSearchMapB220total] = useState('')
  const [pessoasSearchMapB221total, setPessoasSearchMapB221total] = useState('')
  const [pessoasSearchMapB222total, setPessoasSearchMapB222total] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC101total, setPessoasSearchMapC101total] = useState('')
  const [pessoasSearchMapC102total, setPessoasSearchMapC102total] = useState('')
  const [pessoasSearchMapC103total, setPessoasSearchMapC103total] = useState('')
  const [pessoasSearchMapC104total, setPessoasSearchMapC104total] = useState('')
  const [pessoasSearchMapC105total, setPessoasSearchMapC105total] = useState('')
  const [pessoasSearchMapC106total, setPessoasSearchMapC106total] = useState('')
  const [pessoasSearchMapC107total, setPessoasSearchMapC107total] = useState('')
  const [pessoasSearchMapC108total, setPessoasSearchMapC108total] = useState('')
  const [pessoasSearchMapC109total, setPessoasSearchMapC109total] = useState('')
  const [pessoasSearchMapC110total, setPessoasSearchMapC110total] = useState('')
  const [pessoasSearchMapC111total, setPessoasSearchMapC111total] = useState('')
  const [pessoasSearchMapC112total, setPessoasSearchMapC112total] = useState('')
  const [pessoasSearchMapC113total, setPessoasSearchMapC113total] = useState('')
  const [pessoasSearchMapC114total, setPessoasSearchMapC114total] = useState('')
  const [pessoasSearchMapC115total, setPessoasSearchMapC115total] = useState('')
  const [pessoasSearchMapC116total, setPessoasSearchMapC116total] = useState('')
  const [pessoasSearchMapC117total, setPessoasSearchMapC117total] = useState('')
  const [pessoasSearchMapC118total, setPessoasSearchMapC118total] = useState('')
  const [pessoasSearchMapC119total, setPessoasSearchMapC119total] = useState('')
  const [pessoasSearchMapC120total, setPessoasSearchMapC120total] = useState('')
  const [pessoasSearchMapC121total, setPessoasSearchMapC121total] = useState('')
  const [pessoasSearchMapC122total, setPessoasSearchMapC122total] = useState('')
  const [pessoasSearchMapC201total, setPessoasSearchMapC201total] = useState('')
  const [pessoasSearchMapC202total, setPessoasSearchMapC202total] = useState('')
  const [pessoasSearchMapC203total, setPessoasSearchMapC203total] = useState('')
  const [pessoasSearchMapC204total, setPessoasSearchMapC204total] = useState('')
  const [pessoasSearchMapC205total, setPessoasSearchMapC205total] = useState('')
  const [pessoasSearchMapC206total, setPessoasSearchMapC206total] = useState('')
  const [pessoasSearchMapC207total, setPessoasSearchMapC207total] = useState('')
  const [pessoasSearchMapC208total, setPessoasSearchMapC208total] = useState('')
  const [pessoasSearchMapC209total, setPessoasSearchMapC209total] = useState('')
  const [pessoasSearchMapC210total, setPessoasSearchMapC210total] = useState('')
  const [pessoasSearchMapC211total, setPessoasSearchMapC211total] = useState('')
  const [pessoasSearchMapC212total, setPessoasSearchMapC212total] = useState('')
  const [pessoasSearchMapC213total, setPessoasSearchMapC213total] = useState('')
  const [pessoasSearchMapC214total, setPessoasSearchMapC214total] = useState('')
  const [pessoasSearchMapC215total, setPessoasSearchMapC215total] = useState('')
  const [pessoasSearchMapC216total, setPessoasSearchMapC216total] = useState('')
  const [pessoasSearchMapC217total, setPessoasSearchMapC217total] = useState('')
  const [pessoasSearchMapC218total, setPessoasSearchMapC218total] = useState('')
  const [pessoasSearchMapC219total, setPessoasSearchMapC219total] = useState('')
  const [pessoasSearchMapC220total, setPessoasSearchMapC220total] = useState('')
  const [pessoasSearchMapC221total, setPessoasSearchMapC221total] = useState('')
  const [pessoasSearchMapC222total, setPessoasSearchMapC222total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapD201total, setPessoasSearchMapD201total] = useState('')
  const [pessoasSearchMapD202total, setPessoasSearchMapD202total] = useState('')
  const [pessoasSearchMapD203total, setPessoasSearchMapD203total] = useState('')
  const [pessoasSearchMapD204total, setPessoasSearchMapD204total] = useState('')
  const [pessoasSearchMapD205total, setPessoasSearchMapD205total] = useState('')
  const [pessoasSearchMapD206total, setPessoasSearchMapD206total] = useState('')
  const [pessoasSearchMapD207total, setPessoasSearchMapD207total] = useState('')
  const [pessoasSearchMapD208total, setPessoasSearchMapD208total] = useState('')
  const [pessoasSearchMapD209total, setPessoasSearchMapD209total] = useState('')
  const [pessoasSearchMapD210total, setPessoasSearchMapD210total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA E

  const [pessoasSearchMapE201total, setPessoasSearchMapE201total] = useState('')
  const [pessoasSearchMapE202total, setPessoasSearchMapE202total] = useState('')
  const [pessoasSearchMapE203total, setPessoasSearchMapE203total] = useState('')
  const [pessoasSearchMapE204total, setPessoasSearchMapE204total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA E

  const [pessoasSearchMapF201total, setPessoasSearchMapF201total] = useState('')
  const [pessoasSearchMapF202total, setPessoasSearchMapF202total] = useState('')
  const [pessoasSearchMapF203total, setPessoasSearchMapF203total] = useState('')
  const [pessoasSearchMapF204total, setPessoasSearchMapF204total] = useState('')

  //variaveis da triagem

  const [pessoasSearchMapTR201total, setPessoasSearchMapTR201total] = useState('')
  const [pessoasSearchMapTR202total, setPessoasSearchMapTR202total] = useState('')


  // Criação das variáveis toalizadoras de valores
  const totalSomaA =
    pessoasSearchMapA101total +
    pessoasSearchMapA102total +
    pessoasSearchMapA103total +
    pessoasSearchMapA104total +
    pessoasSearchMapA105total +
    pessoasSearchMapA106total +
    pessoasSearchMapA107total +
    pessoasSearchMapA108total +
    pessoasSearchMapA109total +
    pessoasSearchMapA110total +
    pessoasSearchMapA111total +
    pessoasSearchMapA112total +
    pessoasSearchMapA113total +
    pessoasSearchMapA114total +
    pessoasSearchMapA115total +
    pessoasSearchMapA116total +
    pessoasSearchMapA117total +
    pessoasSearchMapA118total +
    pessoasSearchMapA119total +
    pessoasSearchMapA120total +
    pessoasSearchMapA121total +
    pessoasSearchMapA122total +
    pessoasSearchMapA201total +
    pessoasSearchMapA202total +
    pessoasSearchMapA203total +
    pessoasSearchMapA204total +
    pessoasSearchMapA205total +
    pessoasSearchMapA206total +
    pessoasSearchMapA207total +
    pessoasSearchMapA208total +
    pessoasSearchMapA209total +
    pessoasSearchMapA210total +
    pessoasSearchMapA211total +
    pessoasSearchMapA212total +
    pessoasSearchMapA213total +
    pessoasSearchMapA214total +
    pessoasSearchMapA215total +
    pessoasSearchMapA216total +
    pessoasSearchMapA217total +
    pessoasSearchMapA218total +
    pessoasSearchMapA219total +
    pessoasSearchMapA220total +
    pessoasSearchMapA221total +
    pessoasSearchMapA222total


  const totalQuadrante1A =

    pessoasSearchMapA101total +
    pessoasSearchMapA102total +
    pessoasSearchMapA103total +
    pessoasSearchMapA104total +
    pessoasSearchMapA105total +
    pessoasSearchMapA106total +
    pessoasSearchMapA107total +
    pessoasSearchMapA108total +
    pessoasSearchMapA109total +
    pessoasSearchMapA110total +
    pessoasSearchMapA111total +
    pessoasSearchMapA112total

  const totalQuadrante2A =
    pessoasSearchMapA113total +
    pessoasSearchMapA114total +
    pessoasSearchMapA115total +
    pessoasSearchMapA116total +
    pessoasSearchMapA117total +
    pessoasSearchMapA118total +
    pessoasSearchMapA119total +
    pessoasSearchMapA120total +
    pessoasSearchMapA121total +
    pessoasSearchMapA122total

  const totalQuadrante3A =
    pessoasSearchMapA201total +
    pessoasSearchMapA202total +
    pessoasSearchMapA203total +
    pessoasSearchMapA204total +
    pessoasSearchMapA205total +
    pessoasSearchMapA206total +
    pessoasSearchMapA207total +
    pessoasSearchMapA208total +
    pessoasSearchMapA209total +
    pessoasSearchMapA210total +
    pessoasSearchMapA211total +
    pessoasSearchMapA212total

  const totalQuadrante4A =
    pessoasSearchMapA213total +
    pessoasSearchMapA214total +
    pessoasSearchMapA215total +
    pessoasSearchMapA216total +
    pessoasSearchMapA217total +
    pessoasSearchMapA218total +
    pessoasSearchMapA219total +
    pessoasSearchMapA220total +
    pessoasSearchMapA221total +
    pessoasSearchMapA222total

  // GALERIA BRAVO 
  const totalSomaB =
    pessoasSearchMapB101total +
    pessoasSearchMapB102total +
    pessoasSearchMapB103total +
    pessoasSearchMapB104total +
    pessoasSearchMapB105total +
    pessoasSearchMapB106total +
    pessoasSearchMapB107total +
    pessoasSearchMapB108total +
    pessoasSearchMapB109total +
    pessoasSearchMapB110total +
    pessoasSearchMapB111total +
    pessoasSearchMapB112total +
    pessoasSearchMapB113total +
    pessoasSearchMapB114total +
    pessoasSearchMapB115total +
    pessoasSearchMapB116total +
    pessoasSearchMapB117total +
    pessoasSearchMapB118total +
    pessoasSearchMapB119total +
    pessoasSearchMapB120total +
    pessoasSearchMapB121total +
    pessoasSearchMapB122total +
    pessoasSearchMapB201total +
    pessoasSearchMapB202total +
    pessoasSearchMapB203total +
    pessoasSearchMapB204total +
    pessoasSearchMapB205total +
    pessoasSearchMapB206total +
    pessoasSearchMapB207total +
    pessoasSearchMapB208total +
    pessoasSearchMapB209total +
    pessoasSearchMapB210total +
    pessoasSearchMapB211total +
    pessoasSearchMapB212total +
    pessoasSearchMapB213total +
    pessoasSearchMapB214total +
    pessoasSearchMapB215total +
    pessoasSearchMapB216total +
    pessoasSearchMapB217total +
    pessoasSearchMapB218total +
    pessoasSearchMapB219total +
    pessoasSearchMapB220total +
    pessoasSearchMapB221total +
    pessoasSearchMapB222total

  // QUADRANTES BRAVO 

  const totalQuadrante1B =

    pessoasSearchMapB101total +
    pessoasSearchMapB102total +
    pessoasSearchMapB103total +
    pessoasSearchMapB104total +
    pessoasSearchMapB105total +
    pessoasSearchMapB106total +
    pessoasSearchMapB107total +
    pessoasSearchMapB108total +
    pessoasSearchMapB109total +
    pessoasSearchMapB110total +
    pessoasSearchMapB111total +
    pessoasSearchMapB112total

  const totalQuadrante2B =
    pessoasSearchMapB113total +
    pessoasSearchMapB114total +
    pessoasSearchMapB115total +
    pessoasSearchMapB116total +
    pessoasSearchMapB117total +
    pessoasSearchMapB118total +
    pessoasSearchMapB119total +
    pessoasSearchMapB120total +
    pessoasSearchMapB121total +
    pessoasSearchMapB122total

  const totalQuadrante3B =
    pessoasSearchMapB201total +
    pessoasSearchMapB202total +
    pessoasSearchMapB203total +
    pessoasSearchMapB204total +
    pessoasSearchMapB205total +
    pessoasSearchMapB206total +
    pessoasSearchMapB207total +
    pessoasSearchMapB208total +
    pessoasSearchMapB209total +
    pessoasSearchMapB210total +
    pessoasSearchMapB211total +
    pessoasSearchMapB212total

  const totalQuadrante4B =
    pessoasSearchMapB213total +
    pessoasSearchMapB214total +
    pessoasSearchMapB215total +
    pessoasSearchMapB216total +
    pessoasSearchMapB217total +
    pessoasSearchMapB218total +
    pessoasSearchMapB219total +
    pessoasSearchMapB220total +
    pessoasSearchMapB221total +
    pessoasSearchMapB222total

  // GALERIA CHARLIE


  const totalSomaC =
    pessoasSearchMapC101total +
    pessoasSearchMapC102total +
    pessoasSearchMapC103total +
    pessoasSearchMapC104total +
    pessoasSearchMapC105total +
    pessoasSearchMapC106total +
    pessoasSearchMapC107total +
    pessoasSearchMapC108total +
    pessoasSearchMapC109total +
    pessoasSearchMapC110total +
    pessoasSearchMapC111total +
    pessoasSearchMapC112total +
    pessoasSearchMapC113total +
    pessoasSearchMapC114total +
    pessoasSearchMapC115total +
    pessoasSearchMapC116total +
    pessoasSearchMapC117total +
    pessoasSearchMapC118total +
    pessoasSearchMapC119total +
    pessoasSearchMapC120total +
    pessoasSearchMapC121total +
    pessoasSearchMapC122total +
    pessoasSearchMapC201total +
    pessoasSearchMapC202total +
    pessoasSearchMapC203total +
    pessoasSearchMapC204total +
    pessoasSearchMapC205total +
    pessoasSearchMapC206total +
    pessoasSearchMapC207total +
    pessoasSearchMapC208total +
    pessoasSearchMapC209total +
    pessoasSearchMapC210total +
    pessoasSearchMapC211total +
    pessoasSearchMapC212total +
    pessoasSearchMapC213total +
    pessoasSearchMapC214total +
    pessoasSearchMapC215total +
    pessoasSearchMapC216total +
    pessoasSearchMapC217total +
    pessoasSearchMapC218total +
    pessoasSearchMapC219total +
    pessoasSearchMapC220total +
    pessoasSearchMapC221total +
    pessoasSearchMapC222total

  // QUADRANTES CHARLIE 

  const totalQuadrante1C =

    pessoasSearchMapC101total +
    pessoasSearchMapC102total +
    pessoasSearchMapC103total +
    pessoasSearchMapC104total +
    pessoasSearchMapC105total +
    pessoasSearchMapC106total +
    pessoasSearchMapC107total +
    pessoasSearchMapC108total +
    pessoasSearchMapC109total +
    pessoasSearchMapC110total +
    pessoasSearchMapC111total +
    pessoasSearchMapC112total

  const totalQuadrante2C =
    pessoasSearchMapC113total +
    pessoasSearchMapC114total +
    pessoasSearchMapC115total +
    pessoasSearchMapC116total +
    pessoasSearchMapC117total +
    pessoasSearchMapC118total +
    pessoasSearchMapC119total +
    pessoasSearchMapC120total +
    pessoasSearchMapC121total +
    pessoasSearchMapC122total

  const totalQuadrante3C =
    pessoasSearchMapC201total +
    pessoasSearchMapC202total +
    pessoasSearchMapC203total +
    pessoasSearchMapC204total +
    pessoasSearchMapC205total +
    pessoasSearchMapC206total +
    pessoasSearchMapC207total +
    pessoasSearchMapC208total +
    pessoasSearchMapC209total +
    pessoasSearchMapC210total +
    pessoasSearchMapC211total +
    pessoasSearchMapC212total

  const totalQuadrante4C =
    pessoasSearchMapC213total +
    pessoasSearchMapC214total +
    pessoasSearchMapC215total +
    pessoasSearchMapC216total +
    pessoasSearchMapC217total +
    pessoasSearchMapC218total +
    pessoasSearchMapC219total +
    pessoasSearchMapC220total +
    pessoasSearchMapC221total +
    pessoasSearchMapC222total



  const totalSomaD =
    pessoasSearchMapD201total +
    pessoasSearchMapD202total +
    pessoasSearchMapD203total +
    pessoasSearchMapD204total +
    pessoasSearchMapD205total +
    pessoasSearchMapD206total +
    pessoasSearchMapD207total +
    pessoasSearchMapD208total +
    pessoasSearchMapD209total +
    pessoasSearchMapD210total



  const totalQuadrante1D =
    pessoasSearchMapD201total +
    pessoasSearchMapD202total +
    pessoasSearchMapD203total +
    pessoasSearchMapD204total +
    pessoasSearchMapD205total +
    pessoasSearchMapD206total


  const totalQuadrante2D =

    pessoasSearchMapD207total +
    pessoasSearchMapD208total +
    pessoasSearchMapD209total +
    pessoasSearchMapD210total

  const totalSomaE =
    pessoasSearchMapE201total +
    pessoasSearchMapE202total +
    pessoasSearchMapE203total +
    pessoasSearchMapE204total

  const totalSomaF =
    pessoasSearchMapF201total +
    pessoasSearchMapF202total +
    pessoasSearchMapF203total +
    pessoasSearchMapF204total


  const totalSomaTR =
    pessoasSearchMapTR201total +
    pessoasSearchMapTR202total


  const totalUp =
    totalSomaA +
    totalSomaB +
    totalSomaC +
    totalSomaD +
    totalSomaE +
    totalSomaF +
    totalSomaTR

  const totalGeral = totalUp + pessoasDomiciliartotal + pessoasHospitaltotal + pessoasPernoitetotal


  useEffect(() => {
    if (totalGeral > 0) {
      setTimeout(() => {

        setIsSkeletonLoading(false);
      }, 1000);
    }
  }, [totalGeral]);





  const paginaTotal = () => {

    setAtivaBotao(1)
    setPagina(1)


  }
  const paginaGaleriaA = () => {
    setAtivaBotao(2)
    setPagina(2)

  }
  const paginaGaleriaB = () => {
    setAtivaBotao(3)
    setPagina(3)

  }
  const paginaGaleriaC = () => {

    setAtivaBotao(4)
    setPagina(4)

  }
  const paginaGaleriaD = () => {

    setAtivaBotao(5)
    setPagina(5)

  }
  const paginaGaleriaE = () => {

    setAtivaBotao(8)
    setPagina(8)

  }
  const paginaGaleriaF = () => {

    setAtivaBotao(9)
    setPagina(9)

  }

  const paginaGaleriaTR = () => {

    setAtivaBotao(6)
    setPagina(6)

  }

  const paginaForaUP = () => {

    setAtivaBotao(7)
    setPagina(7)

  }


  // A101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA101 = "A101";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA101(filteredResults);
      setPessoasSearchMapA101total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA101).toUpperCase())
  }, [galerias])

  // A102
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA102 = "A102";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA102(filteredResults);
      setPessoasSearchMapA102total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA102).toUpperCase())
  }, [galerias])

  // A103
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA103 = "A103";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA103(filteredResults);
      setPessoasSearchMapA103total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA103).toUpperCase())
  }, [galerias])

  // A104
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA104 = "A104";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA104(filteredResults);
      setPessoasSearchMapA104total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA104).toUpperCase())
  }, [galerias])

  // A105
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA105 = "A105";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA105(filteredResults);
      setPessoasSearchMapA105total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA105).toUpperCase())
  }, [galerias])

  // A106
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA106 = "A106";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA106(filteredResults);
      setPessoasSearchMapA106total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA106).toUpperCase())
  }, [galerias])

  // A107
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA107 = "A107";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA107(filteredResults);
      setPessoasSearchMapA107total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA107).toUpperCase())
  }, [galerias])


  // A108
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA108 = "A108";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA108(filteredResults);
      setPessoasSearchMapA108total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA108).toUpperCase())
  }, [galerias])
  // A109
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA109 = "A109";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA109(filteredResults);
      setPessoasSearchMapA109total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA109).toUpperCase())
  }, [galerias])

  // A110
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA110 = "A110";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA110(filteredResults);
      setPessoasSearchMapA110total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA110).toUpperCase())
  }, [galerias])
  // A111
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA111 = "A111";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA111(filteredResults);
      setPessoasSearchMapA111total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA111).toUpperCase())
  }, [galerias])

  // A112
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA112 = "A112";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA112(filteredResults);
      setPessoasSearchMapA112total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA112).toUpperCase())
  }, [galerias])


  // A113
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA113 = "A113";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA113(filteredResults);
      setPessoasSearchMapA113total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA113).toUpperCase())
  }, [galerias])

  // A114
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA114 = "A114";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA114(filteredResults);
      setPessoasSearchMapA114total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA114).toUpperCase())
  }, [galerias])

  // A115
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA115 = "A115";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA115(filteredResults);
      setPessoasSearchMapA115total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA115).toUpperCase())
  }, [galerias])

  // A116
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA116 = "A116";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA116(filteredResults);
      setPessoasSearchMapA116total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA116).toUpperCase())
  }, [galerias])


  // A117
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA117 = "A117";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA117(filteredResults);
      setPessoasSearchMapA117total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA117).toUpperCase())
  }, [galerias])

  // A118
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA118 = "A118";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA118(filteredResults);
      setPessoasSearchMapA118total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA118).toUpperCase())
  }, [galerias])

  // A119
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA119 = "A119";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA119(filteredResults);
      setPessoasSearchMapA119total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA119).toUpperCase())
  }, [galerias])

  // A120
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA120 = "A120";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA120(filteredResults);
      setPessoasSearchMapA120total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA120).toUpperCase())
  }, [galerias])

  // A121
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA121 = "A121";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA121(filteredResults);
      setPessoasSearchMapA121total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA121).toUpperCase())
  }, [galerias])

  // A122
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA122 = "A122";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA122(filteredResults);
      setPessoasSearchMapA122total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA122).toUpperCase())
  }, [galerias])
  ///////////////////////////////////////////////////////////////////////


  // A201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA201 = "A201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA201(filteredResults);
      setPessoasSearchMapA201total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA201).toUpperCase())
  }, [galerias])

  // A202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA202 = "A202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA202(filteredResults);
      setPessoasSearchMapA202total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA202).toUpperCase())
  }, [galerias])

  // A203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA203 = "A203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA203(filteredResults);
      setPessoasSearchMapA203total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA203).toUpperCase())
  }, [galerias])

  // A204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA204 = "A204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA204(filteredResults);
      setPessoasSearchMapA204total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA204).toUpperCase())
  }, [galerias])
  // A205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA205 = "A205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA205(filteredResults);
      setPessoasSearchMapA205total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA205).toUpperCase())
  }, [galerias])

  // A206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA206 = "A206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA206(filteredResults);
      setPessoasSearchMapA206total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA206).toUpperCase())
  }, [galerias])

  // A207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA207 = "A207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA207(filteredResults);
      setPessoasSearchMapA207total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA207).toUpperCase())
  }, [galerias])


  // A208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA208 = "A208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA208(filteredResults);
      setPessoasSearchMapA208total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA208).toUpperCase())
  }, [galerias])
  // A209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA209 = "A209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA209(filteredResults);
      setPessoasSearchMapA209total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA209).toUpperCase())
  }, [galerias])

  // A210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA210 = "A210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA210(filteredResults);
      setPessoasSearchMapA210total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA210).toUpperCase())
  }, [galerias])
  // A211
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA211 = "A211";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA211(filteredResults);
      setPessoasSearchMapA211total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA211).toUpperCase())
  }, [galerias])

  // A212
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA212 = "A212";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA212(filteredResults);
      setPessoasSearchMapA212total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA212).toUpperCase())
  }, [galerias])

  // A213
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA213 = "A213";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA213(filteredResults);
      setPessoasSearchMapA213total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA213).toUpperCase())
  }, [galerias])

  // A214
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA214 = "A214";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA214(filteredResults);
      setPessoasSearchMapA214total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA214).toUpperCase())
  }, [galerias])

  // A215
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA215 = "A215";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA215(filteredResults);
      setPessoasSearchMapA215total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA215).toUpperCase())
  }, [galerias])

  // A216
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA216 = "A216";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA216(filteredResults);
      setPessoasSearchMapA216total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA216).toUpperCase())
  }, [galerias])


  // A217
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA217 = "A217";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA217(filteredResults);
      setPessoasSearchMapA217total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA217).toUpperCase())
  }, [galerias])

  // A218
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA218 = "A218";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA218(filteredResults);
      setPessoasSearchMapA218total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA218).toUpperCase())
  }, [galerias])

  // A219
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA219 = "A219";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA219(filteredResults);
      setPessoasSearchMapA219total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA219).toUpperCase())
  }, [galerias])

  // A220
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA220 = "A220";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA220(filteredResults);
      setPessoasSearchMapA220total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA220).toUpperCase())
  }, [galerias])

  // A221
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA221 = "A221";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA221(filteredResults);
      setPessoasSearchMapA221total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA221).toUpperCase())
  }, [galerias])

  // A222
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA222 = "A222";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA222(filteredResults);
      setPessoasSearchMapA222total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA222).toUpperCase())
  }, [galerias])

  // B101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB101 = "B101";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB101(filteredResults);
      setPessoasSearchMapB101total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB101).toUpperCase())
  }, [galerias])

  // B102
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB102 = "B102";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB102(filteredResults);
      setPessoasSearchMapB102total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB102).toUpperCase())
  }, [galerias])

  // B103
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB103 = "B103";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB103(filteredResults);
      setPessoasSearchMapB103total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB103).toUpperCase())
  }, [galerias])

  // B104
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB104 = "B104";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB104(filteredResults);
      setPessoasSearchMapB104total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB104).toUpperCase())
  }, [galerias])
  // B105
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB105 = "B105";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB105(filteredResults);
      setPessoasSearchMapB105total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB105).toUpperCase())
  }, [galerias])

  // B106
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB106 = "B106";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB106(filteredResults);
      setPessoasSearchMapB106total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB106).toUpperCase())
  }, [galerias])

  // B107
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB107 = "B107";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB107(filteredResults);
      setPessoasSearchMapB107total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB107).toUpperCase())
  }, [galerias])


  // B108
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB108 = "B108";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB108(filteredResults);
      setPessoasSearchMapB108total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB108).toUpperCase())
  }, [galerias])
  // B109
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB109 = "B109";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB109(filteredResults);
      setPessoasSearchMapB109total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB109).toUpperCase())
  }, [galerias])

  // B110
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB110 = "B110";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB110(filteredResults);
      setPessoasSearchMapB110total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB110).toUpperCase())
  }, [galerias])
  // B111
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB111 = "B111";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB111(filteredResults);
      setPessoasSearchMapB111total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB111).toUpperCase())
  }, [galerias])

  // B112
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB112 = "B112";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB112(filteredResults);
      setPessoasSearchMapB112total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB112).toUpperCase())
  }, [galerias])


  // B113
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB113 = "B113";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB113(filteredResults);
      setPessoasSearchMapB113total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB113).toUpperCase())
  }, [galerias])

  // B114
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB114 = "B114";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB114(filteredResults);
      setPessoasSearchMapB114total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB114).toUpperCase())
  }, [galerias])

  // B115
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB115 = "B115";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB115(filteredResults);
      setPessoasSearchMapB115total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB115).toUpperCase())
  }, [galerias])

  // B116
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB116 = "B116";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB116(filteredResults);
      setPessoasSearchMapB116total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB116).toUpperCase())
  }, [galerias])


  // B117
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB117 = "B117";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB117(filteredResults);
      setPessoasSearchMapB117total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB117).toUpperCase())
  }, [galerias])

  // B118
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB118 = "B118";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB118(filteredResults);
      setPessoasSearchMapB118total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB118).toUpperCase())
  }, [galerias])

  // B119
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB119 = "B119";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB119(filteredResults);
      setPessoasSearchMapB119total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB119).toUpperCase())
  }, [galerias])

  // B120
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB120 = "B120";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB120(filteredResults);
      setPessoasSearchMapB120total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB120).toUpperCase())
  }, [galerias])

  // B121
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB121 = "B121";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB121(filteredResults);
      setPessoasSearchMapB121total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB121).toUpperCase())
  }, [galerias])

  // B122
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB122 = "B122";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB122(filteredResults);
      setPessoasSearchMapB122total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB122).toUpperCase())
  }, [galerias])
  ///////////////////////////////////////////////////////////////////////


  // B201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB201 = "B201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB201(filteredResults);
      setPessoasSearchMapB201total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB201).toUpperCase())
  }, [galerias])

  // B202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB202 = "B202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB202(filteredResults);
      setPessoasSearchMapB202total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB202).toUpperCase())
  }, [galerias])

  // B203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB203 = "B203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB203(filteredResults);
      setPessoasSearchMapB203total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB203).toUpperCase())
  }, [galerias])

  // B204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB204 = "B204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB204(filteredResults);
      setPessoasSearchMapB204total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB204).toUpperCase())
  }, [galerias])
  // B205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB205 = "B205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB205(filteredResults);
      setPessoasSearchMapB205total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB205).toUpperCase())
  }, [galerias])

  // B206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB206 = "B206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB206(filteredResults);
      setPessoasSearchMapB206total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB206).toUpperCase())
  }, [galerias])

  // B207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB207 = "B207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB207(filteredResults);
      setPessoasSearchMapB207total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB207).toUpperCase())
  }, [galerias])


  // B208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB208 = "B208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB208(filteredResults);
      setPessoasSearchMapB208total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB208).toUpperCase())
  }, [galerias])
  // B209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB209 = "B209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB209(filteredResults);
      setPessoasSearchMapB209total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB209).toUpperCase())
  }, [galerias])

  // B210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB210 = "B210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB210(filteredResults);
      setPessoasSearchMapB210total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB210).toUpperCase())
  }, [galerias])
  // B211
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB211 = "B211";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB211(filteredResults);
      setPessoasSearchMapB211total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB211).toUpperCase())
  }, [galerias])

  // B212
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB212 = "B212";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB212(filteredResults);
      setPessoasSearchMapB212total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB212).toUpperCase())
  }, [galerias])

  // B213
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB213 = "B213";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB213(filteredResults);
      setPessoasSearchMapB213total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB213).toUpperCase())
  }, [galerias])

  // B214
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB214 = "B214";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB214(filteredResults);
      setPessoasSearchMapB214total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB214).toUpperCase())
  }, [galerias])

  // B215
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB215 = "B215";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB215(filteredResults);
      setPessoasSearchMapB215total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB215).toUpperCase())
  }, [galerias])

  // B216
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB216 = "B216";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB216(filteredResults);
      setPessoasSearchMapB216total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB216).toUpperCase())
  }, [galerias])


  // B217
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB217 = "B217";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB217(filteredResults);
      setPessoasSearchMapB217total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB217).toUpperCase())
  }, [galerias])

  // B218
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB218 = "B218";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB218(filteredResults);
      setPessoasSearchMapB218total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB218).toUpperCase())
  }, [galerias])

  // B219
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB219 = "B219";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB219(filteredResults);
      setPessoasSearchMapB219total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB219).toUpperCase())
  }, [galerias])

  // B220
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB220 = "B220";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB220(filteredResults);
      setPessoasSearchMapB220total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB220).toUpperCase())
  }, [galerias])

  // B221
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB221 = "B221";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB221(filteredResults);
      setPessoasSearchMapB221total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB221).toUpperCase())
  }, [galerias])

  // B222
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB222 = "B222";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB222(filteredResults);
      setPessoasSearchMapB222total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB222).toUpperCase())
  }, [galerias])

  //C C101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC101 = "C101";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC101(filteredResults);
      setPessoasSearchMapC101total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC101).toUpperCase())
  }, [galerias])

  // C102
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC102 = "C102";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC102(filteredResults);
      setPessoasSearchMapC102total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC102).toUpperCase())
  }, [galerias])

  // C103
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC103 = "C103";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC103(filteredResults);
      setPessoasSearchMapC103total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC103).toUpperCase())
  }, [galerias])

  // C104
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC104 = "C104";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC104(filteredResults);
      setPessoasSearchMapC104total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC104).toUpperCase())
  }, [galerias])
  // C105
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC105 = "C105";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC105(filteredResults);
      setPessoasSearchMapC105total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC105).toUpperCase())
  }, [galerias])

  // C106
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC106 = "C106";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC106(filteredResults);
      setPessoasSearchMapC106total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC106).toUpperCase())
  }, [galerias])

  // C107
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC107 = "C107";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC107(filteredResults);
      setPessoasSearchMapC107total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC107).toUpperCase())
  }, [galerias])


  // C108
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC108 = "C108";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC108(filteredResults);
      setPessoasSearchMapC108total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC108).toUpperCase())
  }, [galerias])
  // C109
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC109 = "C109";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC109(filteredResults);
      setPessoasSearchMapC109total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC109).toUpperCase())
  }, [galerias])

  // C110
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC110 = "C110";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC110(filteredResults);
      setPessoasSearchMapC110total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC110).toUpperCase())
  }, [galerias])
  // C111
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC111 = "C111";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC111(filteredResults);
      setPessoasSearchMapC111total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC111).toUpperCase())
  }, [galerias])

  // C112
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC112 = "C112";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC112(filteredResults);
      setPessoasSearchMapC112total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC112).toUpperCase())
  }, [galerias])


  // C113
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC113 = "C113";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC113(filteredResults);
      setPessoasSearchMapC113total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC113).toUpperCase())
  }, [galerias])

  // C114
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC114 = "C114";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC114(filteredResults);
      setPessoasSearchMapC114total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC114).toUpperCase())
  }, [galerias])

  // C115
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC115 = "C115";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC115(filteredResults);
      setPessoasSearchMapC115total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC115).toUpperCase())
  }, [galerias])

  // C116
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC116 = "C116";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC116(filteredResults);
      setPessoasSearchMapC116total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC116).toUpperCase())
  }, [galerias])


  // C117
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC117 = "C117";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC117(filteredResults);
      setPessoasSearchMapC117total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC117).toUpperCase())
  }, [galerias])

  // C118
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC118 = "C118";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC118(filteredResults);
      setPessoasSearchMapC118total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC118).toUpperCase())
  }, [galerias])

  // C119
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC119 = "C119";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC119(filteredResults);
      setPessoasSearchMapC119total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC119).toUpperCase())
  }, [galerias])

  // C120
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC120 = "C120";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC120(filteredResults);
      setPessoasSearchMapC120total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC120).toUpperCase())
  }, [galerias])

  // C121
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC121 = "C121";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC121(filteredResults);
      setPessoasSearchMapC121total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC121).toUpperCase())
  }, [galerias])

  // C122
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC122 = "C122";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC122(filteredResults);
      setPessoasSearchMapC122total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC122).toUpperCase())
  }, [galerias])
  ///////////////////////////////////////////////////////////////////////


  // C201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC201 = "C201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC201(filteredResults);
      setPessoasSearchMapC201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC201).toUpperCase())
  }, [galerias])

  // C202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC202 = "C202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC202(filteredResults);
      setPessoasSearchMapC202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC202).toUpperCase())
  }, [galerias])

  // C203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC203 = "C203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC203(filteredResults);
      setPessoasSearchMapC203total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC203).toUpperCase())
  }, [galerias])

  // C204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC204 = "C204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC204(filteredResults);
      setPessoasSearchMapC204total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC204).toUpperCase())
  }, [galerias])
  // C205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC205 = "C205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC205(filteredResults);
      setPessoasSearchMapC205total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC205).toUpperCase())
  }, [galerias])

  // C206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC206 = "C206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC206(filteredResults);
      setPessoasSearchMapC206total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC206).toUpperCase())
  }, [galerias])

  // C207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC207 = "C207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC207(filteredResults);
      setPessoasSearchMapC207total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC207).toUpperCase())
  }, [galerias])


  // C208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC208 = "C208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC208(filteredResults);
      setPessoasSearchMapC208total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC208).toUpperCase())
  }, [galerias])
  // C209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC209 = "C209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC209(filteredResults);
      setPessoasSearchMapC209total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC209).toUpperCase())
  }, [galerias])

  // C210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC210 = "C210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC210(filteredResults);
      setPessoasSearchMapC210total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC210).toUpperCase())
  }, [galerias])
  // C211
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC211 = "C211";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC211(filteredResults);
      setPessoasSearchMapC211total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC211).toUpperCase())
  }, [galerias])

  // C212
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC212 = "C212";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC212(filteredResults);
      setPessoasSearchMapC212total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC212).toUpperCase())
  }, [galerias])

  // C213
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC213 = "C213";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC213(filteredResults);
      setPessoasSearchMapC213total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC213).toUpperCase())
  }, [galerias])

  // C214
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC214 = "C214";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC214(filteredResults);
      setPessoasSearchMapC214total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC214).toUpperCase())
  }, [galerias])

  // C215
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC215 = "C215";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC215(filteredResults);
      setPessoasSearchMapC215total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC215).toUpperCase())
  }, [galerias])

  // C216
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC216 = "C216";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC216(filteredResults);
      setPessoasSearchMapC216total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC216).toUpperCase())
  }, [galerias])


  // C217
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC217 = "C217";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC217(filteredResults);
      setPessoasSearchMapC217total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC217).toUpperCase())
  }, [galerias])

  // C218
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC218 = "C218";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC218(filteredResults);
      setPessoasSearchMapC218total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC218).toUpperCase())
  }, [galerias])

  // C219
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC219 = "C219";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC219(filteredResults);
      setPessoasSearchMapC219total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC219).toUpperCase())
  }, [galerias])

  // C220
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC220 = "C220";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC220(filteredResults);
      setPessoasSearchMapC220total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC220).toUpperCase())
  }, [galerias])

  // C221
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC221 = "C221";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC221(filteredResults);
      setPessoasSearchMapC221total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC221).toUpperCase())
  }, [galerias])

  // C222
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC222 = "C222";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC222(filteredResults);
      setPessoasSearchMapC222total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC222).toUpperCase())
  }, [galerias])

  // D201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD201 = "D201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD201(filteredResults);
      setPessoasSearchMapD201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD201).toUpperCase())
  }, [galerias])

  // D202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD202 = "D202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD202(filteredResults);
      setPessoasSearchMapD202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD202).toUpperCase())
  }, [galerias])

  // D203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD203 = "D203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD203(filteredResults);
      setPessoasSearchMapD203total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD203).toUpperCase())
  }, [galerias])

  // D204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD204 = "D204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD204(filteredResults);
      setPessoasSearchMapD204total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD204).toUpperCase())
  }, [galerias])
  // D205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD205 = "D205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD205(filteredResults);
      setPessoasSearchMapD205total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD205).toUpperCase())
  }, [galerias])

  // D206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD206 = "D206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD206(filteredResults);
      setPessoasSearchMapD206total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD206).toUpperCase())
  }, [galerias])

  // D207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD207 = "D207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD207(filteredResults);
      setPessoasSearchMapD207total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD207).toUpperCase())
  }, [galerias])


  // D208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD208 = "D208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD208(filteredResults);
      setPessoasSearchMapD208total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD208).toUpperCase())
  }, [galerias])
  // D209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD209 = "D209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD209(filteredResults);
      setPessoasSearchMapD209total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD209).toUpperCase())
  }, [galerias])

  // D210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD210 = "D210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD210(filteredResults);
      setPessoasSearchMapD210total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD210).toUpperCase())
  }, [galerias])

  // GALERIA E
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE201 = "E201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE201(filteredResults);
      setPessoasSearchMapE201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE201).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE202 = "E202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE202(filteredResults);
      setPessoasSearchMapE202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE202).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE203 = "E203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE203(filteredResults);
      setPessoasSearchMapE203total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE203).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE204 = "E204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE204(filteredResults);
      setPessoasSearchMapE204total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE204).toUpperCase())
  }, [galerias])

  // GALERIA F
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF201 = "F201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF201(filteredResults);
      setPessoasSearchMapF201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF201).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF202 = "F202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF202(filteredResults);
      setPessoasSearchMapF202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF202).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF203 = "F203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF203(filteredResults);
      setPessoasSearchMapF203total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF203).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaF204 = "F204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapF204(filteredResults);
      setPessoasSearchMapF204total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaF204).toUpperCase())
  }, [galerias])
  // TR201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR201 = "TR201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR201(filteredResults);
      setPessoasSearchMapTR201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR201).toUpperCase())
  }, [galerias])


  // TR202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR202 = "TR202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR202(filteredResults);
      setPessoasSearchMapTR202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR202).toUpperCase())
  }, [galerias])



  // DOMICILIAR
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let domiciliar = "DOMICILIAR";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasDomiciliar(filteredResults);
      setPessoasDomiciliartotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((domiciliar).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let hospital = "HOSPITAL";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasHospital(filteredResults);
      setPessoasHospitaltotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((hospital).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let pernoite = "PERNOITE";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasPernoite(filteredResults);
      setPessoasPernoitetotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((pernoite).toUpperCase())
  }, [galerias])





  return (<>
    {loading && <Loading></Loading>}
    <div className={styles.divtabs}>


      <Modal isOpen={modalBuscaAberto} toggle={toggleModalBusca} style={{ maxWidth: '1300px', width: '100%' }}>
        <ModalBody>
          <div style={{ marginTop: 15, fontFamily: 'policiapenal' }}><center> <p style={{ fontFamily: 'policiapenal', padding: 10, }}><img src={logo} alt="Logo" height={80} style={{ marginBottom: 10 }} /><h2>POLICIA PENAL | ES</h2><h5><b>BUSCA AVANÇADA</b></h5> </p> </center></div>

          <center>

            <input
              type="text"
              autoFocus
              value={termoBusca}
              onChange={(e) => setTermoBusca(e.target.value.toLocaleUpperCase())}
              placeholder="Nome, Infopen, Data de Entrada, Cela, Artigo ..."
              ref={inputBuscaRef}
              style={{
                padding: '10px',
                fontSize: '20px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                width: '80%',
                boxSizing: 'border-box',
                outline: 'none',
                transition: 'box-shadow 0.3s ease',
                marginBottom: 60,
                marginTop: 30

              }}
            />

            {resultadosBusca.length > 0 && <h5>Registros Encontrados: ({resultadosBusca.length})</h5>}  </center>
          <br></br>
          {resultadosBusca.length > 0 && <thead >

            <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd', borderRadius: '15px' }}>
              <tr style={{ backgroundColor: "#253221", color: '#8a9175', borderRadius: '15px', fontWeight: 'lighter' }}>
                <th style={{ width: '200px', textAlign: 'center', padding: '10px' }}></th>
                <th style={{ width: '380px', textAlign: 'center', padding: '10px' }}>Nome</th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Cela</th>
                <th style={{ width: '120px', textAlign: 'center', padding: '10px' }}>Entrada</th>
                <th style={{ width: '120px', textAlign: 'center', padding: '10px' }}>Infopen</th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Visita</th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Artigo</th>
              </tr>
            </table></thead>}

          {resultadosBusca.map((resultado, index) => (
            <div key={index}>
              <table onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}

                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  border: '1px solid #ddd',
                  borderRadius: '15px',
                  cursor: 'pointer'
                }}>

                <tbody>

                  <tr onClick={() => handleCliqueBuscaCela(resultado.cela, resultado.nome)} style={{
                    backgroundColor: hoveredIndex === index ? '#b8d5c1' : '#c3c8cd',
                    transition: 'background-color 0.3s ease', marginTop: 40
                  }}>

                    <td style={{ width: '50px', textAlign: 'center' }}>
                      <img
                        alt={resultado.nome}
                        width='auto'
                        height={75}
                        src={resultado.imgURL ? resultado.imgURL : userDef}
                        style={{ width: '80px', cursor: 'pointer', maxWidth: '80px', maxHeight: '80px' }}
                      />
                    </td>
                    <td style={{ width: '350px', textAlign: 'center', padding: '10px' }}><b>{resultado.nome}</b></td>
                    <td style={{ width: '80px', textAlign: 'center', padding: '10px' }}><b>{resultado.cela}</b></td>
                    <td style={{ width: '80px', textAlign: 'center', padding: '10px', fontSize: 14 }}>{resultado.selectDate}</td>

                    <td style={{ width: '100px', textAlign: 'center', padding: '10px' }}>
                      <a target="_blank" rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: 'black', fontSize: 14
                        }}
                        href={'https://infopen.sejus.es.gov.br/Identificacao/Presos.aspx?ID=' + resultado.infopen}>
                        {resultado.infopen} <FaEye />
                      </a>
                    </td>
                    <td style={{ width: '100px', textAlign: 'center', padding: '10px' }}>
                      <a target="_blank" rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          fontSize: 14
                        }} href={'https://diasdevisitas.com.br/resultado2.php?digitado=' + resultado.infopen}>
                        {resultado.visita} <FaEye />
                      </a>
                    </td>
                    <td style={{ width: '100px', textAlign: 'center', padding: '10px', fontSize: 12 }}>{resultado.artigo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

        </ModalBody>

        <center> <button onClick={toggleModalBusca} style={{ backgroundColor: 'gray', color: 'white', fontFamily: 'policiapenal', fontSize: 14, padding: 14, marginBottom: 30, borderRadius: 10, border: 1 }}>FECHAR</button></center>
      </Modal>


      <center>
        <div className={styles.containerSelect} style={{ marginTop: 10, marginBottom: 30 }}>
          <select className={styles.selectTablet} style={{ height: 40, fontSize: 15, }} onChange={(event) => {
            const selectedValue = event.target.value;
            switch (selectedValue) {
              case 'galeriaA':
                paginaGaleriaA();
                break;
              case 'galeriaB':
                paginaGaleriaB();
                break;
              case 'galeriaC':
                paginaGaleriaC();
                break;
              case 'galeriaD':
                paginaGaleriaD();
                break;
              case 'galeriaE':
                paginaGaleriaE();
                break;
              case 'galeriaF':
                paginaGaleriaF();
                break;
              case 'galeriaTR':
                paginaGaleriaTR();
                break;
              case 'foraUP':
                paginaForaUP();
                break;
              case 'totalGeral':
                paginaTotal();
                break;
              default:
                break;
            }
          }}>
            <option value="galeriaA" selected={ativaBotao === 2}>GALERIA (A)</option>
            <option value="galeriaB" selected={ativaBotao === 3}>GALERIA (B)</option>
            <option value="galeriaC" selected={ativaBotao === 4}>GALERIA (C)</option>
            <option value="galeriaD" selected={ativaBotao === 5}>GALERIA (D)</option>
            <option value="galeriaTR" selected={ativaBotao === 6}>TRIAGEM (TR)</option>
            <option value="foraUP" selected={ativaBotao === 7}>FORA DA UNIDADE</option>
            <option value="totalGeral" selected={ativaBotao === 1}>QUANTITATIVO</option>
          </select>

        </div>
      </center>

      <div id="tab1" onClick={paginaGaleriaA}  >
        <p className={styles.dataColumnTopo} style={ativaBotao === 2 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }} >GALERIA (A)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab2" onClick={paginaGaleriaB} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 3 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (B)</p>

      </div>

      &nbsp; &nbsp; &nbsp;

      <div id="tab3" onClick={paginaGaleriaC} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 4 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (C)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaD} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 5 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (D)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaE} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 8 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (E)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaF} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 9 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (F)</p>

      </div>
      &nbsp; &nbsp; &nbsp;


      <div id="tab8" onClick={paginaForaUP}>
        <p className={styles.dataColumnTopo} style={ativaBotao === 7 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>FORA DA UNIDADE</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab6" onClick={paginaTotal}>
        <p className={styles.dataColumnTopo} style={ativaBotao === 1 ? { color: "green", cursor: 'pointer', paddingTop: 14, marginRight: 20 } : { cursor: 'pointer', paddingTop: 14, marginRight: 20 }}>TOTAL GERAL</p>
      </div>
      <div onClick={toggleModalBusca} style={{ cursor: 'pointer' }} id="tab10" >
        <button className={styles.dataColumnTopo} style={{ backgroundColor: '#545f34', borderRadius: 5, color: '#b8c495', lineHeight: 2.5, display: 'flex', flexDirection: 'row' }} >BUSCAR &nbsp;<FaSearch style={{ marginTop: 12 }} /></button>
      </div>

    </div>



    {pagina === 1 && <div style={{ fontFamily: 'policiapenal' }}> <center> <h1><strong>CONTAGEM GERAL</strong></h1><p>TOTALIZADORES POR GALERIAS E VAGAS</p><br></br></center>
      <div>
        <center><div className={styles.quadroFora}>
          <Table dark>
            <tbody style={{ boxShadow: '2px 3px 1px #888888', lineHeight: '25px' }} >
              <tr style={{ height: 42 }}>
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }} scope="row"><center>LOCAL</center>
                  <center><b></b></center>

                </th>
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>PRESOS

                </th>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>VAGAS

                </td>

                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>VAGAS %

                </td>
              </tr>
              <tr>
                <th style={{ color: "black", fontSize: "18px", letterSpacing: "0.5px", verticalAlign: "middle", justifyItems: "right", textAlign: "right", backgroundColor: "#959595", fontFamily: "policiapenal", fontWeight: 'bold' }} scope="row">
                  <p><center> &nbsp;&nbsp;&nbsp;GALERIA A </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA B </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA C </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA D </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA E </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA F </center> </p>

                </th>
                <td style={{ fontSize: "17px", backgroundColor: "#959595", letterSpacing: "2px", fontWeight: "bold", verticalAlign: "middle", color: "black", paddingLeft: "15px" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaA}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaB}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaC}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaD}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaE}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaF}</p>)}

                </td>
                <td className={styles.dataColumnTopo} style={{ verticalAlign: "middle", fontSize: "17px", letterSpacing: "2px", color: "black", backgroundColor: "#959595" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{144 - totalSomaA}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{176 - totalSomaB}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{176 - totalSomaC}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{40 - totalSomaD}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{16 - totalSomaE}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{16 - totalSomaF}</p>)}
                </td>
                <td className={styles.dataColumnTopo} style={{ verticalAlign: "middle", fontSize: "17px", letterSpacing: "2px", color: "black", backgroundColor: "#959595" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((144 - totalSomaA) * 100) / 580).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((176 - totalSomaB) * 100) / 580).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((176 - totalSomaC) * 100) / 580).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((40 - totalSomaD) * 100) / 580).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((16 - totalSomaE) * 100) / 580).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((16 - totalSomaF) * 100) / 580).toFixed(1)}%</p>)}

                </td>

              </tr>
              <tr >
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175' }} scope="row">
                  <center><b>TOTAL NA UNIDADE</b></center>

                </th>
                <td style={{ fontSize: "17px", backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175', letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong>{totalUp}</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", color: '#8a9175', fontSize: "17px", letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong> {
                    144 - totalSomaA +
                    176 - totalSomaB +
                    176 - totalSomaC +
                    40 - totalSomaD +
                    16 - totalSomaE +
                    16 - totalSomaF
                  }</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ letterSpacing: "1px", fontSize: "17px", verticalAlign: "middle", fontWeight: 'bolder', backgroundColor: "#253221", color: '#8a9175' }}>
                  {!isSkeletonLoading && <strong>   {(
                    parseFloat((((144 - totalSomaA) * 100 / 568).toFixed(1))) +
                    parseFloat((((176 - totalSomaB) * 100 / 568).toFixed(1))) +
                    parseFloat((((176 - totalSomaC) * 100 / 568).toFixed(1))) +
                    parseFloat((((40 - totalSomaD) * 100 / 568).toFixed(1))) +
                    parseFloat((((16 - totalSomaE) * 100 / 568).toFixed(1))) +
                    parseFloat((((16 - totalSomaF) * 100 / 568).toFixed(1)))

                  ).toFixed(2)} % </strong>}


                </td>


              </tr>
              <tr >
                <th scope="row" style={{ backgroundColor: "#959595", color: 'black' }}>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>HOSPITAL</center></p>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>PERNOITE</center></p>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>DOMICILIAR</center></p>
                </th>
                <td style={{ backgroundColor: "#959595", color: 'black' }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasHospitaltotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasPernoitetotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasDomiciliartotal}</p>)}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#959595" }}>

                </td >
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#959595" }}>

                </td>

              </tr>
              <tr>
                <th style={{ borderBottom: "none", backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175' }} scope="row">
                  <center><b>TOTAL GERAL</b></center>
                </th>
                <td style={{ borderBottom: "none", fontSize: "17px", backgroundColor: "#253221", verticalAlign: "middle", color: "#8a9175", letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong>{totalGeral}</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", borderBottom: "none" }}>

                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", borderBottom: "none" }}>
                </td>
              </tr>
            </tbody>
          </Table>
        </div></center> <center><br></br> <h4 className={styles.dataColumnTopo}><strong>TOTALIZADORES POR QUADRANTE</strong></h4><p></p></center>
        <center><br /><div className={styles.quadroFora}>

          <Table >

            <tbody className={styles.dataColumnTopo}>
              <td style={{ backgroundColor: 'black', boxShadow: '5px 5px 10px #888888' }} >
                <div style={{ backgroundColor: '#959595' }}>
                  <center><p style={{ fontSize: '21px', backgroundColor: "#253221", color: '#8a9175' }} ><b>GALERIA A</b></p>
                    <p><b>A101 - A112 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante1A}</smal></b></p>
                    <p><b>A113 - A122 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante2A}</smal></b></p>
                    <p><b>A201 - A212 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante3A}</smal></b></p>
                    <p><b>A213 - A222 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante4A}</smal></b></p></center></div>
              </td>
              &nbsp;
              <td style={{ backgroundColor: 'black', boxShadow: '5px 5px 10px #888888' }}>
                <div style={{ backgroundColor: '#959595' }}>
                  <center><p style={{ fontSize: '21px', backgroundColor: "#253221", color: '#8a9175' }} ><b>GALERIA B</b></p>
                    <p><b>B101 - B112 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante1B}</smal></b></p>
                    <p><b>B113 - B122 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante2B}</smal></b></p>
                    <p><b>B201 - B212 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante3B}</smal></b></p>
                    <p><b>B213 - B222 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante4B}</smal></b></p></center></div>
              </td>
              &nbsp;
              <td style={{ backgroundColor: 'black', boxShadow: '5px 5px 10px #888888' }}>
                <div style={{ backgroundColor: '#959595' }}>
                  <center><p style={{ fontSize: '21px', backgroundColor: "#253221", color: '#8a9175' }} ><b>GALERIA C</b></p>
                    <p><b>C101 - C112 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante1C}</smal></b></p>
                    <p><b>C113 - C122 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante2C}</smal></b></p>
                    <p><b>C201 - C212 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante3C}</smal></b></p>
                    <p><b>C213 - C222 : <smal style={{ color: "black", paddingLeft: 12 }} >{totalQuadrante4C}</smal></b></p></center></div>
              </td>
              &nbsp;
              <td style={{ backgroundColor: 'black', boxShadow: '5px 5px 10px #888888' }}>
                <div style={{ backgroundColor: '#959595' }}>
                  <center><p style={{ fontSize: '21px', backgroundColor: "#253221", color: '#8a9175' }} ><b>GALERIA D</b></p>
                    <p><b>D201 - D206 : <smal style={{ color: "black", paddingLeft: 12 }}  >{totalQuadrante1D}</smal></b></p>
                    <p><b>D207 - D210 : <smal style={{ color: "black", paddingLeft: 12 }}  >{totalQuadrante2D}</smal></b></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </center>
                </div>
              </td>
              &nbsp;

              &nbsp;
            </tbody>

          </Table>
          <br />
        </div></center>
      </div>
    </div>}

    <div id="someLista">
      <div className={styles.home}>
        <br></br>
        <br></br>

        {pagina === 2 && <>
          <h1 ><strong>GALERIA "A"</strong>({totalSomaA})</h1><br></br>

          <div className={styles.quadroGeral} >
            <div className={styles.myButtonQG} onClick={handleButtonClickA101} style={quadranteA === 1 ? { backgroundColor: "#253221", cursor: 'pointer', border: '2px solid green' } : { cursor: 'pointer', backgroundColor: 'gray' }}> A101 - A118 </div>
            <div className={styles.myButtonQG} onClick={handleButtonClickA113} style={quadranteA === 2 ? { backgroundColor: "#253221", cursor: 'pointer', border: '2px solid green' } : { cursor: 'pointer', backgroundColor: 'gray' }}> A201 - A218 </div>

          </div  >

          {quadranteA === 1 && <>

            <div id="A101"></div><Cela cela="A101" usuario={usuario} celaTotal={pessoasSearchMapA101total} celaMap={(pessoasSearchMapA101 && pessoasSearchMapA101)} nomeBuscado={nomeBuscado} />
            <div id="A102"></div><Cela cela="A102" usuario={usuario} celaTotal={pessoasSearchMapA102total} celaMap={(pessoasSearchMapA102 && pessoasSearchMapA102)} nomeBuscado={nomeBuscado} />
            <div id="A103"></div><Cela cela="A103" usuario={usuario} celaTotal={pessoasSearchMapA103total} celaMap={(pessoasSearchMapA103 && pessoasSearchMapA103)} nomeBuscado={nomeBuscado} />
            <div id="A104"></div><Cela cela="A104" usuario={usuario} celaTotal={pessoasSearchMapA104total} celaMap={(pessoasSearchMapA104 && pessoasSearchMapA104)} nomeBuscado={nomeBuscado} />
            <div id="A105"></div><Cela cela="A105" usuario={usuario} celaTotal={pessoasSearchMapA105total} celaMap={(pessoasSearchMapA105 && pessoasSearchMapA105)} nomeBuscado={nomeBuscado} />
            <div id="A106"></div><Cela cela="A106" usuario={usuario} celaTotal={pessoasSearchMapA106total} celaMap={(pessoasSearchMapA106 && pessoasSearchMapA106)} nomeBuscado={nomeBuscado} />
            <div id="A107"></div><Cela cela="A107" usuario={usuario} celaTotal={pessoasSearchMapA107total} celaMap={(pessoasSearchMapA107 && pessoasSearchMapA107)} nomeBuscado={nomeBuscado} />
            <div id="A108"></div><Cela cela="A108" usuario={usuario} celaTotal={pessoasSearchMapA108total} celaMap={(pessoasSearchMapA108 && pessoasSearchMapA108)} nomeBuscado={nomeBuscado} />
            <div id="A109"></div><Cela cela="A109" usuario={usuario} celaTotal={pessoasSearchMapA109total} celaMap={(pessoasSearchMapA109 && pessoasSearchMapA109)} nomeBuscado={nomeBuscado} />
            <div id="A110"></div><Cela cela="A110" usuario={usuario} celaTotal={pessoasSearchMapA110total} celaMap={(pessoasSearchMapA110 && pessoasSearchMapA110)} nomeBuscado={nomeBuscado} />
            <div id="A111"></div><Cela cela="A111" usuario={usuario} celaTotal={pessoasSearchMapA111total} celaMap={(pessoasSearchMapA111 && pessoasSearchMapA111)} nomeBuscado={nomeBuscado} />
            <div id="A112"></div><Cela cela="A112" usuario={usuario} celaTotal={pessoasSearchMapA112total} celaMap={(pessoasSearchMapA112 && pessoasSearchMapA112)} nomeBuscado={nomeBuscado} />




            <div id="A113"></div> <Cela cela="A113" usuario={usuario} celaTotal={pessoasSearchMapA113total} celaMap={(pessoasSearchMapA113 && pessoasSearchMapA113)} nomeBuscado={nomeBuscado} />
            <div id="A114"></div> <Cela cela="A114" usuario={usuario} celaTotal={pessoasSearchMapA114total} celaMap={(pessoasSearchMapA114 && pessoasSearchMapA114)} nomeBuscado={nomeBuscado} />
            <div id="A115"></div><Cela cela="A115" usuario={usuario} celaTotal={pessoasSearchMapA115total} celaMap={(pessoasSearchMapA115 && pessoasSearchMapA115)} nomeBuscado={nomeBuscado} />
            <div id="A116"></div> <Cela cela="A116" usuario={usuario} celaTotal={pessoasSearchMapA116total} celaMap={(pessoasSearchMapA116 && pessoasSearchMapA116)} nomeBuscado={nomeBuscado} />
            <div id="A117"></div> <Cela cela="A117" usuario={usuario} celaTotal={pessoasSearchMapA117total} celaMap={(pessoasSearchMapA117 && pessoasSearchMapA117)} nomeBuscado={nomeBuscado} />
            <div id="A118"></div> <Cela cela="A118" usuario={usuario} celaTotal={pessoasSearchMapA118total} celaMap={(pessoasSearchMapA118 && pessoasSearchMapA118)} nomeBuscado={nomeBuscado} />

            <br></br><br></br> </>}
          {quadranteA === 2 && <>

            <div id="A201"></div> <Cela cela="A201" usuario={usuario} celaTotal={pessoasSearchMapA201total} celaMap={(pessoasSearchMapA201 && pessoasSearchMapA201)} nomeBuscado={nomeBuscado} />
            <div id="A202"></div><Cela cela="A202" usuario={usuario} celaTotal={pessoasSearchMapA202total} celaMap={(pessoasSearchMapA202 && pessoasSearchMapA202)} nomeBuscado={nomeBuscado} />
            <div id="A203"></div><Cela cela="A203" usuario={usuario} celaTotal={pessoasSearchMapA203total} celaMap={(pessoasSearchMapA203 && pessoasSearchMapA203)} nomeBuscado={nomeBuscado} />
            <div id="A204"></div><Cela cela="A204" usuario={usuario} celaTotal={pessoasSearchMapA204total} celaMap={(pessoasSearchMapA204 && pessoasSearchMapA204)} nomeBuscado={nomeBuscado} />
            <div id="A205"></div><Cela cela="A205" usuario={usuario} celaTotal={pessoasSearchMapA205total} celaMap={(pessoasSearchMapA205 && pessoasSearchMapA205)} nomeBuscado={nomeBuscado} />
            <div id="A206"></div><Cela cela="A206" usuario={usuario} celaTotal={pessoasSearchMapA206total} celaMap={(pessoasSearchMapA206 && pessoasSearchMapA206)} nomeBuscado={nomeBuscado} />
            <div id="A207"></div> <Cela cela="A207" usuario={usuario} celaTotal={pessoasSearchMapA207total} celaMap={(pessoasSearchMapA207 && pessoasSearchMapA207)} nomeBuscado={nomeBuscado} />
            <div id="A208"></div><Cela cela="A208" usuario={usuario} celaTotal={pessoasSearchMapA208total} celaMap={(pessoasSearchMapA208 && pessoasSearchMapA208)} nomeBuscado={nomeBuscado} />
            <div id="A209"></div><Cela cela="A209" usuario={usuario} celaTotal={pessoasSearchMapA209total} celaMap={(pessoasSearchMapA209 && pessoasSearchMapA209)} nomeBuscado={nomeBuscado} />
            <div id="A210"></div> <Cela cela="A210" usuario={usuario} celaTotal={pessoasSearchMapA210total} celaMap={(pessoasSearchMapA210 && pessoasSearchMapA210)} nomeBuscado={nomeBuscado} />
            <div id="A211"></div><Cela cela="A211" usuario={usuario} celaTotal={pessoasSearchMapA211total} celaMap={(pessoasSearchMapA211 && pessoasSearchMapA211)} nomeBuscado={nomeBuscado} />
            <div id="A212"></div><Cela cela="A212" usuario={usuario} celaTotal={pessoasSearchMapA212total} celaMap={(pessoasSearchMapA212 && pessoasSearchMapA212)} nomeBuscado={nomeBuscado} />
            <br></br><br></br>

            <div id="A213"></div><Cela cela="A213" usuario={usuario} celaTotal={pessoasSearchMapA213total} celaMap={(pessoasSearchMapA213 && pessoasSearchMapA213)} nomeBuscado={nomeBuscado} />
            <div id="A214"></div><Cela cela="A214" usuario={usuario} celaTotal={pessoasSearchMapA214total} celaMap={(pessoasSearchMapA214 && pessoasSearchMapA214)} nomeBuscado={nomeBuscado} />
            <div id="A215"></div><Cela cela="A215" usuario={usuario} celaTotal={pessoasSearchMapA215total} celaMap={(pessoasSearchMapA215 && pessoasSearchMapA215)} nomeBuscado={nomeBuscado} />
            <div id="A216"></div><Cela cela="A216" usuario={usuario} celaTotal={pessoasSearchMapA216total} celaMap={(pessoasSearchMapA216 && pessoasSearchMapA216)} nomeBuscado={nomeBuscado} />
            <div id="A217"></div><Cela cela="A217" usuario={usuario} celaTotal={pessoasSearchMapA217total} celaMap={(pessoasSearchMapA217 && pessoasSearchMapA217)} nomeBuscado={nomeBuscado} />
            <div id="A218"></div> <Cela cela="A218" usuario={usuario} celaTotal={pessoasSearchMapA218total} celaMap={(pessoasSearchMapA218 && pessoasSearchMapA218)} nomeBuscado={nomeBuscado} />
          </>}
        </>}

        {pagina === 3 && <><h1 ><strong>GALERIA "B"</strong>({totalSomaB})</h1>
          <br></br> <div className={styles.quadroGeral} >
            <div className={styles.myButtonQG} onClick={handleButtonClickB101} style={quadranteB === 1 ? { backgroundColor: "#253221", cursor: 'pointer', border: '2px solid green' } : { cursor: 'pointer', backgroundColor: 'gray' }}> B101 - B122 </div>
            <div className={styles.myButtonQG} onClick={handleButtonClickB113} style={quadranteB === 2 ? { backgroundColor: "#253221", cursor: 'pointer', border: '2px solid green' } : { cursor: 'pointer', backgroundColor: 'gray' }}> B201 - B222 </div>


          </div>

          {quadranteB === 1 && <>

            <div id="B101"></div> <Cela cela="B101" usuario={usuario} celaTotal={pessoasSearchMapB101total} celaMap={(pessoasSearchMapB101 && pessoasSearchMapB101)} nomeBuscado={nomeBuscado} />
            <div id="B102"></div><Cela cela="B102" usuario={usuario} celaTotal={pessoasSearchMapB102total} celaMap={(pessoasSearchMapB102 && pessoasSearchMapB102)} nomeBuscado={nomeBuscado} />
            <div id="B103"></div><Cela cela="B103" usuario={usuario} celaTotal={pessoasSearchMapB103total} celaMap={(pessoasSearchMapB103 && pessoasSearchMapB103)} nomeBuscado={nomeBuscado} />
            <div id="B104"></div><Cela cela="B104" usuario={usuario} celaTotal={pessoasSearchMapB104total} celaMap={(pessoasSearchMapB104 && pessoasSearchMapB104)} nomeBuscado={nomeBuscado} />
            <div id="B105"></div><Cela cela="B105" usuario={usuario} celaTotal={pessoasSearchMapB105total} celaMap={(pessoasSearchMapB105 && pessoasSearchMapB105)} nomeBuscado={nomeBuscado} />
            <div id="B106"></div><Cela cela="B106" usuario={usuario} celaTotal={pessoasSearchMapB106total} celaMap={(pessoasSearchMapB106 && pessoasSearchMapB106)} nomeBuscado={nomeBuscado} />
            <div id="B107"></div><Cela cela="B107" usuario={usuario} celaTotal={pessoasSearchMapB107total} celaMap={(pessoasSearchMapB107 && pessoasSearchMapB107)} nomeBuscado={nomeBuscado} />
            <div id="B108"></div><Cela cela="B108" usuario={usuario} celaTotal={pessoasSearchMapB108total} celaMap={(pessoasSearchMapB108 && pessoasSearchMapB108)} nomeBuscado={nomeBuscado} />
            <div id="B109"></div> <Cela cela="B109" usuario={usuario} celaTotal={pessoasSearchMapB109total} celaMap={(pessoasSearchMapB109 && pessoasSearchMapB109)} nomeBuscado={nomeBuscado} />
            <div id="B110"></div><Cela cela="B110" usuario={usuario} celaTotal={pessoasSearchMapB110total} celaMap={(pessoasSearchMapB110 && pessoasSearchMapB110)} nomeBuscado={nomeBuscado} />
            <div id="B111"></div><Cela cela="B111" usuario={usuario} celaTotal={pessoasSearchMapB111total} celaMap={(pessoasSearchMapB111 && pessoasSearchMapB111)} nomeBuscado={nomeBuscado} />
            <div id="B112"></div><Cela cela="B112" usuario={usuario} celaTotal={pessoasSearchMapB112total} celaMap={(pessoasSearchMapB112 && pessoasSearchMapB112)} nomeBuscado={nomeBuscado} />
            <br></br><br></br>


            <div id="B113"></div><Cela cela="B113" usuario={usuario} celaTotal={pessoasSearchMapB113total} celaMap={(pessoasSearchMapB113 && pessoasSearchMapB113)} nomeBuscado={nomeBuscado} />
            <div id="B114"></div><Cela cela="B114" usuario={usuario} celaTotal={pessoasSearchMapB114total} celaMap={(pessoasSearchMapB114 && pessoasSearchMapB114)} nomeBuscado={nomeBuscado} />
            <div id="B115"></div><Cela cela="B115" usuario={usuario} celaTotal={pessoasSearchMapB115total} celaMap={(pessoasSearchMapB115 && pessoasSearchMapB115)} nomeBuscado={nomeBuscado} />
            <div id="B116"></div><Cela cela="B116" usuario={usuario} celaTotal={pessoasSearchMapB116total} celaMap={(pessoasSearchMapB116 && pessoasSearchMapB116)} nomeBuscado={nomeBuscado} />
            <div id="B117"></div> <Cela cela="B117" usuario={usuario} celaTotal={pessoasSearchMapB117total} celaMap={(pessoasSearchMapB117 && pessoasSearchMapB117)} nomeBuscado={nomeBuscado} />
            <div id="B118"></div> <Cela cela="B118" usuario={usuario} celaTotal={pessoasSearchMapB118total} celaMap={(pessoasSearchMapB118 && pessoasSearchMapB118)} nomeBuscado={nomeBuscado} />
            <div id="B119"></div><Cela cela="B119" usuario={usuario} celaTotal={pessoasSearchMapB119total} celaMap={(pessoasSearchMapB119 && pessoasSearchMapB119)} nomeBuscado={nomeBuscado} />
            <div id="B120"></div> <Cela cela="B120" usuario={usuario} celaTotal={pessoasSearchMapB120total} celaMap={(pessoasSearchMapB120 && pessoasSearchMapB120)} nomeBuscado={nomeBuscado} />
            <div id="B121"></div> <Cela cela="B121" usuario={usuario} celaTotal={pessoasSearchMapB121total} celaMap={(pessoasSearchMapB121 && pessoasSearchMapB121)} nomeBuscado={nomeBuscado} />
            <div id="B122"></div> <Cela cela="B122" usuario={usuario} celaTotal={pessoasSearchMapB122total} celaMap={(pessoasSearchMapB122 && pessoasSearchMapB122)} nomeBuscado={nomeBuscado} />

            <br></br><br></br></>}

          {quadranteB === 2 && <>

            <div id="B201"></div> <Cela cela="B201" usuario={usuario} celaTotal={pessoasSearchMapB201total} celaMap={(pessoasSearchMapB201 && pessoasSearchMapB201)} nomeBuscado={nomeBuscado} />
            <div id="B202"></div> <Cela cela="B202" usuario={usuario} celaTotal={pessoasSearchMapB202total} celaMap={(pessoasSearchMapB202 && pessoasSearchMapB202)} nomeBuscado={nomeBuscado} />
            <div id="B203"></div><Cela cela="B203" usuario={usuario} celaTotal={pessoasSearchMapB203total} celaMap={(pessoasSearchMapB203 && pessoasSearchMapB203)} nomeBuscado={nomeBuscado} />
            <div id="B204"></div><Cela cela="B204" usuario={usuario} celaTotal={pessoasSearchMapB204total} celaMap={(pessoasSearchMapB204 && pessoasSearchMapB204)} nomeBuscado={nomeBuscado} />
            <div id="B205"></div><Cela cela="B205" usuario={usuario} celaTotal={pessoasSearchMapB205total} celaMap={(pessoasSearchMapB205 && pessoasSearchMapB205)} nomeBuscado={nomeBuscado} />
            <div id="B206"></div> <Cela cela="B206" usuario={usuario} celaTotal={pessoasSearchMapB206total} celaMap={(pessoasSearchMapB206 && pessoasSearchMapB206)} nomeBuscado={nomeBuscado} />
            <div id="B207"></div> <Cela cela="B207" usuario={usuario} celaTotal={pessoasSearchMapB207total} celaMap={(pessoasSearchMapB207 && pessoasSearchMapB207)} nomeBuscado={nomeBuscado} />
            <div id="B208"></div><Cela cela="B208" usuario={usuario} celaTotal={pessoasSearchMapB208total} celaMap={(pessoasSearchMapB208 && pessoasSearchMapB208)} nomeBuscado={nomeBuscado} />
            <div id="B209"></div> <Cela cela="B209" usuario={usuario} celaTotal={pessoasSearchMapB209total} celaMap={(pessoasSearchMapB209 && pessoasSearchMapB209)} nomeBuscado={nomeBuscado} />
            <div id="B210"></div> <Cela cela="B210" usuario={usuario} celaTotal={pessoasSearchMapB210total} celaMap={(pessoasSearchMapB210 && pessoasSearchMapB210)} nomeBuscado={nomeBuscado} />
            <div id="B211"></div> <Cela cela="B211" usuario={usuario} celaTotal={pessoasSearchMapB211total} celaMap={(pessoasSearchMapB211 && pessoasSearchMapB211)} nomeBuscado={nomeBuscado} />
            <div id="B212"></div> <Cela cela="B212" usuario={usuario} celaTotal={pessoasSearchMapB212total} celaMap={(pessoasSearchMapB212 && pessoasSearchMapB212)} nomeBuscado={nomeBuscado} />
            <br></br><br></br>


            <div id="B213"></div> <Cela cela="B213" usuario={usuario} celaTotal={pessoasSearchMapB213total} celaMap={(pessoasSearchMapB213 && pessoasSearchMapB213)} nomeBuscado={nomeBuscado} />
            <div id="B214"></div> <Cela cela="B214" usuario={usuario} celaTotal={pessoasSearchMapB214total} celaMap={(pessoasSearchMapB214 && pessoasSearchMapB214)} nomeBuscado={nomeBuscado} />
            <div id="B215"></div> <Cela cela="B215" usuario={usuario} celaTotal={pessoasSearchMapB215total} celaMap={(pessoasSearchMapB215 && pessoasSearchMapB215)} nomeBuscado={nomeBuscado} />
            <div id="B216"></div> <Cela cela="B216" usuario={usuario} celaTotal={pessoasSearchMapB216total} celaMap={(pessoasSearchMapB216 && pessoasSearchMapB216)} nomeBuscado={nomeBuscado} />
            <div id="B217"></div> <Cela cela="B217" usuario={usuario} celaTotal={pessoasSearchMapB217total} celaMap={(pessoasSearchMapB217 && pessoasSearchMapB217)} nomeBuscado={nomeBuscado} />
            <div id="B218"></div> <Cela cela="B218" usuario={usuario} celaTotal={pessoasSearchMapB218total} celaMap={(pessoasSearchMapB218 && pessoasSearchMapB218)} nomeBuscado={nomeBuscado} />
            <div id="B219"></div> <Cela cela="B219" usuario={usuario} celaTotal={pessoasSearchMapB219total} celaMap={(pessoasSearchMapB219 && pessoasSearchMapB219)} nomeBuscado={nomeBuscado} />
            <div id="B220"></div> <Cela cela="B220" usuario={usuario} celaTotal={pessoasSearchMapB220total} celaMap={(pessoasSearchMapB220 && pessoasSearchMapB220)} nomeBuscado={nomeBuscado} />
            <div id="B221"></div> <Cela cela="B221" usuario={usuario} celaTotal={pessoasSearchMapB221total} celaMap={(pessoasSearchMapB221 && pessoasSearchMapB221)} nomeBuscado={nomeBuscado} />
            <div id="B222"></div> <Cela cela="B222" usuario={usuario} celaTotal={pessoasSearchMapB222total} celaMap={(pessoasSearchMapB222 && pessoasSearchMapB222)} nomeBuscado={nomeBuscado} /></>}
        </>}

        {pagina === 4 && <>
          <h1 ><strong>GALERIA "C"</strong>({totalSomaC})</h1>
          <br></br> <div className={styles.quadroGeral} >
            <div className={styles.myButtonQG} onClick={handleButtonClickC101} style={quadranteC === 1 ? { backgroundColor: "#253221", cursor: 'pointer', border: '2px solid green' } : { cursor: 'pointer', backgroundColor: 'gray' }}> C101 - C122 </div>
            <div className={styles.myButtonQG} onClick={handleButtonClickC113} style={quadranteC === 2 ? { backgroundColor: "#253221", cursor: 'pointer', border: '2px solid green' } : { cursor: 'pointer', backgroundColor: 'gray' }}> C201 - C222 </div>


          </div>

          {quadranteC === 1 && <>
            <div id="C101"></div><Cela cela="C101" usuario={usuario} celaTotal={pessoasSearchMapC101total} celaMap={(pessoasSearchMapC101 && pessoasSearchMapC101)} nomeBuscado={nomeBuscado} />
            <div id="C102"></div><Cela cela="C102" usuario={usuario} celaTotal={pessoasSearchMapC102total} celaMap={(pessoasSearchMapC102 && pessoasSearchMapC102)} nomeBuscado={nomeBuscado} />
            <div id="C103"></div><Cela cela="C103" usuario={usuario} celaTotal={pessoasSearchMapC103total} celaMap={(pessoasSearchMapC103 && pessoasSearchMapC103)} nomeBuscado={nomeBuscado} />
            <div id="C104"></div><Cela cela="C104" usuario={usuario} celaTotal={pessoasSearchMapC104total} celaMap={(pessoasSearchMapC104 && pessoasSearchMapC104)} nomeBuscado={nomeBuscado} />
            <div id="C105"></div><Cela cela="C105" usuario={usuario} celaTotal={pessoasSearchMapC105total} celaMap={(pessoasSearchMapC105 && pessoasSearchMapC105)} nomeBuscado={nomeBuscado} />
            <div id="C106"></div><Cela cela="C106" usuario={usuario} celaTotal={pessoasSearchMapC106total} celaMap={(pessoasSearchMapC106 && pessoasSearchMapC106)} nomeBuscado={nomeBuscado} />
            <div id="C107"></div><Cela cela="C107" usuario={usuario} celaTotal={pessoasSearchMapC107total} celaMap={(pessoasSearchMapC107 && pessoasSearchMapC107)} nomeBuscado={nomeBuscado} />
            <div id="C108"></div><Cela cela="C108" usuario={usuario} celaTotal={pessoasSearchMapC108total} celaMap={(pessoasSearchMapC108 && pessoasSearchMapC108)} nomeBuscado={nomeBuscado} />
            <div id="C109"></div><Cela cela="C109" usuario={usuario} celaTotal={pessoasSearchMapC109total} celaMap={(pessoasSearchMapC109 && pessoasSearchMapC109)} nomeBuscado={nomeBuscado} />
            <div id="C110"></div><Cela cela="C110" usuario={usuario} celaTotal={pessoasSearchMapC110total} celaMap={(pessoasSearchMapC110 && pessoasSearchMapC110)} nomeBuscado={nomeBuscado} />
            <div id="C111"></div><Cela cela="C111" usuario={usuario} celaTotal={pessoasSearchMapC111total} celaMap={(pessoasSearchMapC111 && pessoasSearchMapC111)} nomeBuscado={nomeBuscado} />
            <div id="C112"></div><Cela cela="C112" usuario={usuario} celaTotal={pessoasSearchMapC112total} celaMap={(pessoasSearchMapC112 && pessoasSearchMapC112)} nomeBuscado={nomeBuscado} />
            <br></br><br></br>

            <div id="C113"></div> <Cela cela="C113" usuario={usuario} celaTotal={pessoasSearchMapC113total} celaMap={(pessoasSearchMapC113 && pessoasSearchMapC113)} nomeBuscado={nomeBuscado} />
            <div id="C114"></div> <Cela cela="C114" usuario={usuario} celaTotal={pessoasSearchMapC114total} celaMap={(pessoasSearchMapC114 && pessoasSearchMapC114)} nomeBuscado={nomeBuscado} />
            <div id="C115"></div><Cela cela="C115" usuario={usuario} celaTotal={pessoasSearchMapC115total} celaMap={(pessoasSearchMapC115 && pessoasSearchMapC115)} nomeBuscado={nomeBuscado} />
            <div id="C116"></div><Cela cela="C116" usuario={usuario} celaTotal={pessoasSearchMapC116total} celaMap={(pessoasSearchMapC116 && pessoasSearchMapC116)} nomeBuscado={nomeBuscado} />
            <div id="C117"></div> <Cela cela="C117" usuario={usuario} celaTotal={pessoasSearchMapC117total} celaMap={(pessoasSearchMapC117 && pessoasSearchMapC117)} nomeBuscado={nomeBuscado} />
            <div id="C118"></div> <Cela cela="C118" usuario={usuario} celaTotal={pessoasSearchMapC118total} celaMap={(pessoasSearchMapC118 && pessoasSearchMapC118)} nomeBuscado={nomeBuscado} />
            <div id="C119"></div> <Cela cela="C119" usuario={usuario} celaTotal={pessoasSearchMapC119total} celaMap={(pessoasSearchMapC119 && pessoasSearchMapC119)} nomeBuscado={nomeBuscado} />
            <div id="C120"></div> <Cela cela="C120" usuario={usuario} celaTotal={pessoasSearchMapC120total} celaMap={(pessoasSearchMapC120 && pessoasSearchMapC120)} nomeBuscado={nomeBuscado} />
            <div id="C121"></div> <Cela cela="C121" usuario={usuario} celaTotal={pessoasSearchMapC121total} celaMap={(pessoasSearchMapC121 && pessoasSearchMapC121)} nomeBuscado={nomeBuscado} />
            <div id="C122"></div> <Cela cela="C122" usuario={usuario} celaTotal={pessoasSearchMapC122total} celaMap={(pessoasSearchMapC122 && pessoasSearchMapC122)} nomeBuscado={nomeBuscado} />

            <br></br><br></br>  </>}
          {quadranteC === 2 && <>
            <div id="C201"></div><Cela cela="C201" usuario={usuario} celaTotal={pessoasSearchMapC201total} celaMap={(pessoasSearchMapC201 && pessoasSearchMapC201)} nomeBuscado={nomeBuscado} />
            <div id="C202"></div><Cela cela="C202" usuario={usuario} celaTotal={pessoasSearchMapC202total} celaMap={(pessoasSearchMapC202 && pessoasSearchMapC202)} nomeBuscado={nomeBuscado} />
            <div id="C203"></div><Cela cela="C203" usuario={usuario} celaTotal={pessoasSearchMapC203total} celaMap={(pessoasSearchMapC203 && pessoasSearchMapC203)} nomeBuscado={nomeBuscado} />
            <div id="C204"></div><Cela cela="C204" usuario={usuario} celaTotal={pessoasSearchMapC204total} celaMap={(pessoasSearchMapC204 && pessoasSearchMapC204)} nomeBuscado={nomeBuscado} />
            <div id="C205"></div><Cela cela="C205" usuario={usuario} celaTotal={pessoasSearchMapC205total} celaMap={(pessoasSearchMapC205 && pessoasSearchMapC205)} nomeBuscado={nomeBuscado} />
            <div id="C206"></div><Cela cela="C206" usuario={usuario} celaTotal={pessoasSearchMapC206total} celaMap={(pessoasSearchMapC206 && pessoasSearchMapC206)} nomeBuscado={nomeBuscado} />
            <div id="C207"></div><Cela cela="C207" usuario={usuario} celaTotal={pessoasSearchMapC207total} celaMap={(pessoasSearchMapC207 && pessoasSearchMapC207)} nomeBuscado={nomeBuscado} />
            <div id="C208"></div><Cela cela="C208" usuario={usuario} celaTotal={pessoasSearchMapC208total} celaMap={(pessoasSearchMapC208 && pessoasSearchMapC208)} nomeBuscado={nomeBuscado} />
            <div id="C209"></div><Cela cela="C209" usuario={usuario} celaTotal={pessoasSearchMapC209total} celaMap={(pessoasSearchMapC209 && pessoasSearchMapC209)} nomeBuscado={nomeBuscado} />
            <div id="C210"></div><Cela cela="C210" usuario={usuario} celaTotal={pessoasSearchMapC210total} celaMap={(pessoasSearchMapC210 && pessoasSearchMapC210)} nomeBuscado={nomeBuscado} />
            <div id="C211"></div><Cela cela="C211" usuario={usuario} celaTotal={pessoasSearchMapC211total} celaMap={(pessoasSearchMapC211 && pessoasSearchMapC211)} nomeBuscado={nomeBuscado} />
            <div id="C212"></div><Cela cela="C212" usuario={usuario} celaTotal={pessoasSearchMapC212total} celaMap={(pessoasSearchMapC212 && pessoasSearchMapC212)} nomeBuscado={nomeBuscado} />
            <br></br><br></br>

            <div id="C213"></div><Cela cela="C213" usuario={usuario} celaTotal={pessoasSearchMapC213total} celaMap={(pessoasSearchMapC213 && pessoasSearchMapC213)} nomeBuscado={nomeBuscado} />
            <div id="C214"></div> <Cela cela="C214" usuario={usuario} celaTotal={pessoasSearchMapC214total} celaMap={(pessoasSearchMapC214 && pessoasSearchMapC214)} nomeBuscado={nomeBuscado} />
            <div id="C215"></div><Cela cela="C215" usuario={usuario} celaTotal={pessoasSearchMapC215total} celaMap={(pessoasSearchMapC215 && pessoasSearchMapC215)} nomeBuscado={nomeBuscado} />
            <div id="C216"></div><Cela cela="C216" usuario={usuario} celaTotal={pessoasSearchMapC216total} celaMap={(pessoasSearchMapC216 && pessoasSearchMapC216)} nomeBuscado={nomeBuscado} />
            <div id="C217"></div><Cela cela="C217" usuario={usuario} celaTotal={pessoasSearchMapC217total} celaMap={(pessoasSearchMapC217 && pessoasSearchMapC217)} nomeBuscado={nomeBuscado} />
            <div id="C218"></div> <Cela cela="C218" usuario={usuario} celaTotal={pessoasSearchMapC218total} celaMap={(pessoasSearchMapC218 && pessoasSearchMapC218)} nomeBuscado={nomeBuscado} />
            <div id="C219"></div> <Cela cela="C219" usuario={usuario} celaTotal={pessoasSearchMapC219total} celaMap={(pessoasSearchMapC219 && pessoasSearchMapC219)} nomeBuscado={nomeBuscado} />
            <div id="C220"></div> <Cela cela="C220" usuario={usuario} celaTotal={pessoasSearchMapC220total} celaMap={(pessoasSearchMapC220 && pessoasSearchMapC220)} nomeBuscado={nomeBuscado} />
            <div id="C221"></div> <Cela cela="C221" usuario={usuario} celaTotal={pessoasSearchMapC221total} celaMap={(pessoasSearchMapC221 && pessoasSearchMapC221)} nomeBuscado={nomeBuscado} />
            <div id="C222"></div><Cela cela="C222" usuario={usuario} celaTotal={pessoasSearchMapC222total} celaMap={(pessoasSearchMapC222 && pessoasSearchMapC222)} nomeBuscado={nomeBuscado} />

          </>}

        </>}
        {pagina === 5 && <>
          <h1 ><strong>GALERIA "D"</strong>({totalSomaD})</h1>
          <br></br><br></br>
          <div id="D201"></div><Cela cela="D201" usuario={usuario} celaTotal={pessoasSearchMapD201total} celaMap={(pessoasSearchMapD201 && pessoasSearchMapD201)} nomeBuscado={nomeBuscado} />
          <div id="D202"></div> <Cela cela="D202" usuario={usuario} celaTotal={pessoasSearchMapD202total} celaMap={(pessoasSearchMapD202 && pessoasSearchMapD202)} nomeBuscado={nomeBuscado} />
          <div id="D203"></div><Cela cela="D203" usuario={usuario} celaTotal={pessoasSearchMapD203total} celaMap={(pessoasSearchMapD203 && pessoasSearchMapD203)} nomeBuscado={nomeBuscado} />
          <div id="D204"></div><Cela cela="D204" usuario={usuario} celaTotal={pessoasSearchMapD204total} celaMap={(pessoasSearchMapD204 && pessoasSearchMapD204)} nomeBuscado={nomeBuscado} />
          <div id="D205"></div><Cela cela="D205" usuario={usuario} celaTotal={pessoasSearchMapD205total} celaMap={(pessoasSearchMapD205 && pessoasSearchMapD205)} nomeBuscado={nomeBuscado} />
          <div id="D206"></div><Cela cela="D206" usuario={usuario} celaTotal={pessoasSearchMapD206total} celaMap={(pessoasSearchMapD206 && pessoasSearchMapD206)} nomeBuscado={nomeBuscado} />
          <div id="D207"></div><Cela cela="D207" usuario={usuario} celaTotal={pessoasSearchMapD207total} celaMap={(pessoasSearchMapD207 && pessoasSearchMapD207)} nomeBuscado={nomeBuscado} />
          <div id="D208"></div><Cela cela="D208" usuario={usuario} celaTotal={pessoasSearchMapD208total} celaMap={(pessoasSearchMapD208 && pessoasSearchMapD208)} nomeBuscado={nomeBuscado} />
          <div id="D209"></div> <Cela cela="D209" usuario={usuario} celaTotal={pessoasSearchMapD209total} celaMap={(pessoasSearchMapD209 && pessoasSearchMapD209)} nomeBuscado={nomeBuscado} />
          <div id="D210"></div><Cela cela="D210" usuario={usuario} celaTotal={pessoasSearchMapD210total} celaMap={(pessoasSearchMapD210 && pessoasSearchMapD210)} nomeBuscado={nomeBuscado} />
        </>}

        {pagina === 8 && <>
          <h1 ><strong>GALERIA "E"</strong>({totalSomaE})</h1>
          <br></br><br></br>
          <div id="E201"></div><Cela cela="E201" usuario={usuario} celaTotal={pessoasSearchMapE201total} celaMap={(pessoasSearchMapE201 && pessoasSearchMapE201)} nomeBuscado={nomeBuscado} />
          <div id="E202"></div><Cela cela="E202" usuario={usuario} celaTotal={pessoasSearchMapE202total} celaMap={(pessoasSearchMapE202 && pessoasSearchMapE202)} nomeBuscado={nomeBuscado} />
          <div id="E203"></div><Cela cela="E203" usuario={usuario} celaTotal={pessoasSearchMapE203total} celaMap={(pessoasSearchMapE203 && pessoasSearchMapE203)} nomeBuscado={nomeBuscado} />
          <div id="E204"></div><Cela cela="E204" usuario={usuario} celaTotal={pessoasSearchMapE204total} celaMap={(pessoasSearchMapE204 && pessoasSearchMapE204)} nomeBuscado={nomeBuscado} />

        </>}
        {pagina === 9 && <>
          <h1 ><strong>GALERIA "F"</strong>({totalSomaF})</h1>
          <br></br><br></br>
          <div id="F201"></div><Cela cela="F201" usuario={usuario} celaTotal={pessoasSearchMapF201total} celaMap={(pessoasSearchMapF201 && pessoasSearchMapF201)} nomeBuscado={nomeBuscado} />
          <div id="F202"></div><Cela cela="F202" usuario={usuario} celaTotal={pessoasSearchMapF202total} celaMap={(pessoasSearchMapF202 && pessoasSearchMapF202)} nomeBuscado={nomeBuscado} />
          <div id="F203"></div><Cela cela="F203" usuario={usuario} celaTotal={pessoasSearchMapF203total} celaMap={(pessoasSearchMapF203 && pessoasSearchMapF203)} nomeBuscado={nomeBuscado} />
          <div id="F204"></div><Cela cela="F204" usuario={usuario} celaTotal={pessoasSearchMapF204total} celaMap={(pessoasSearchMapF204 && pessoasSearchMapF204)} nomeBuscado={nomeBuscado} />

        </>}

        {pagina === 6 && <>
          <h1 ><strong>TRIAGEM "TR"</strong>({totalSomaTR})</h1>

          <div id="TR201"></div><Cela usuario={usuario} cela="TR201" celaTotal={pessoasSearchMapTR201total} celaMap={(pessoasSearchMapTR201 && pessoasSearchMapTR201)} nomeBuscado={nomeBuscado} />
          <div id="TR202"></div><Cela usuario={usuario} cela="TR202" celaTotal={pessoasSearchMapTR202total} celaMap={(pessoasSearchMapTR202 && pessoasSearchMapTR202)} nomeBuscado={nomeBuscado} />
        </>}

        {pagina === 7 && <> <h1><strong> PRESOS FORA DA UNIDADE</strong></h1>

          <br></br><br></br>
          <div id="PRISÃO DOMICILIAR"></div> <Cela usuario={usuario} cela="PRISÃO DOMICILIAR" celaTotal={pessoasDomiciliartotal} celaMap={(pessoasDomiciliar && pessoasDomiciliar)} />

          <div id="INTERNAÇÃO HOSPITALAR"></div> <Cela usuario={usuario} cela="INTERNAÇÃO HOSPITALAR" presoForaTitulo={1} celaTotal={pessoasHospitaltotal} celaMap={(pessoasHospital && pessoasHospital)} />

          <div id="PERNOITE FORA"></div><Cela usuario={usuario} cela="PERNOITE FORA" presoForaTitulo={1} celaTotal={pessoasPernoitetotal} celaMap={(pessoasPernoite && pessoasPernoite)} />


          <br />


        </>}


      </div>
      <br></br><br></br><br></br>
    </div>
  </>
  )
}

export default Home