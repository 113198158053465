// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBuYF6uotDCMtsx9xgH5uQBFc45me1o1NQ",
  authDomain: "ppcdpvv.firebaseapp.com",
  projectId: "ppcdpvv",
  storageBucket: "ppcdpvv.appspot.com",
  messagingSenderId: "750196699954",
  appId: "1:750196699954:web:a959a89d6cb8c6c39d5d2b",
  measurementId: "G-WXKM5EZBPX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

const db = getFirestore(app);

export { db };



