import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.png';
import logotexto from '../../assets/img/ntext.png';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; // Import additional Firebase services as needed
import styless from './Login.module.css'

const firebaseConfig = {
  apiKey: "AIzaSyBuYF6uotDCMtsx9xgH5uQBFc45me1o1NQ",
  authDomain: "ppcdpvv.firebaseapp.com",
  projectId: "ppcdpvv",
  storageBucket: "ppcdpvv.appspot.com",
  messagingSenderId: "750196699954",
  appId: "1:750196699954:web:a959a89d6cb8c6c39d5d2b",
  measurementId: "G-WXKM5EZBPX"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth(); // Get a reference to auth service



const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false)
  
 
useEffect(() => {

  const handleTimeout = () => {
    setError(null); // Limpa o erro após 2 segundos
  };

  if (error !== null && error.includes('password is invalid ')) {
    setError('Senha Incorreta')

  } 
  if (error !== null && error.includes('corresponding to this identifier')) {
    setError('Usuário não Autorizado');
  } 
  if (error !== null && error.includes('Access to this account has been temporarily disabled due to many failed login attempts.')) {
    setError('Usuário temporariamente desabilitado devido a muitas tentativas de login.');
  } 
  if (error !== null && error.includes('network')) {
    setError('Sem Internet');
  } 
  if (error !== null && error.includes('auth/invalid-login-credentials')) {
    setError('Senha Incorreta');
  } 
  

  // Chamando a função handleTimeout após 2 segundos
  const timeoutId = setTimeout(handleTimeout, 4000);

  // Limpando o timeout quando o componente é desmontado ou quando o efeito é executado novamente
  return () => clearTimeout(timeoutId);

}, [error])


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    setLoad(true)
    e.preventDefault();
    setError(null); // Clear any previous errors

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // User is successfully logged in, handle success scenario (e.g., navigate to another page)
      console.log("deu certo")
      console.log('User logged in:', userCredential.user);
      setLoad(false)
    } catch (error) {
      setError(error.message); // Set error message for display
      setLoad(false)
    }
  };

   

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} className={styless.divLogo11} style={styles.form}>
        <div style={styles.formGroup}>
          <div style={styles.logoBox}>
            <img src={logo}  alt="Logo" style={styles.logo}  />
            
          </div><center><b style={{fontFamily:'policiapenal', fontWeight:'bold', marginBottom:20, fontSize:46}}>CDPVV</b></center>
          <label htmlFor="email" style={styles.label}>Usuário</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="password" style={styles.label}>Senha</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            style={styles.input}
            required
          />
        </div>
        <button type="submit" disabled={load} style={styles.button}>ENTRAR</button>
        <br></br>
        {error && <center><p style={{ color: '#253221' }}>{error}</p></center>}
        {error == null && <center><p style={{ color: '#253221' }}>&nbsp;</p></center>}
      </form>

      <form onSubmit={handleSubmit} className={styless.divLogo2} >
        <div style={styles.formGroup} className={styless.divLogo2}>
          <div style={styles.logoBox}>
            <img src={logo}  alt="Logo" style={styles.logo}  />
          </div>
          <label htmlFor="email" style={styles.label}>Usuário:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            style={styles.inputTablet}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="password" style={styles.label}>Senha:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            style={styles.inputTablet}
            required
          />
        </div>
        <button type="submit" disabled={load} style={styles.button}>ENTRAR</button>
        <br></br>
        {error && <center><p style={{ color: '#253221' }}>{error}</p></center>}
        {error == null && <center><p style={{ color: '#253221' }}>&nbsp;</p></center>}
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'rgb(214, 220, 221)',
    fontFamily: 'policiapenal',
  },
  title: {
    marginBottom: '1.5rem',
    fontSize: '2rem',
    color: '#333',
    alignItems: 'center',
  },
  form: {
    width: '400px',
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  label: {
    marginBottom: '0.5rem',
    color: '#333',
    fontSize: '1.1rem',
  },
  input: {
    width: '100%',
    padding: '0.8rem',
    fontSize: '1rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  inputTablet: {
    width: '100%',
    padding: '0.8rem',
    fontSize: '1rem',
    borderRadius: '4px',
    backgroundColor:'white',
    border: '1px solid #253221'
  },
  button: {
    width: '100%',
    padding: '1rem',
    fontSize: '1rem',
    backgroundColor: '#253221',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontFamily: 'policiapenal',
  },
  logo: {
    width: '220px', // Defina o tamanho da largura da logo conforme necessário
    height: 'auto', // Mantém a proporção da altura automaticamente
    marginBottom: '5px',
  },
  logotexto: {
    width: '165px', // Defina o tamanho da largura da logo conforme necessário
    height: 'auto', // Mantém a proporção da altura automaticamente
  
  },

  
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  
};

export default Login;
